import React, { useState, useEffect } from "react";
import styles from "./Projetos.module.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Modal from "react-modal";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

Modal.setAppElement("#root"); // Define o elemento raiz da aplicação para acessibilidade

const Projetos = ({ estrutura, projetos }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleItems, setVisibleItems] = useState(4);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProjectIndex, setSelectedProjectIndex] = useState(null);

  useEffect(() => {
    const updateVisibleItems = () => {
      if (window.innerWidth < 600) {
        setVisibleItems(1);
      } else if (window.innerWidth < 800) {
        setVisibleItems(2);
      } else if (window.innerWidth < 1100) {
        setVisibleItems(3);
      } else if (window.innerWidth < 1420) {
        setVisibleItems(4);
      }
    };

    updateVisibleItems();

    window.addEventListener("resize", updateVisibleItems);

    return () => {
      window.removeEventListener("resize", updateVisibleItems);
    };
  }, []);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - visibleItems, 0));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + visibleItems, projetos.length - visibleItems)
    );
  };

  const openModal = (index) => {
    setSelectedProjectIndex(index);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedProjectIndex(null);
  };

  const selectedProject = projetos[selectedProjectIndex];

  return (
    <div className={styles.estruturaParent}>
      <h3>{estrutura}</h3>
      <div className={styles.divsedesContWrapper}>
        {currentIndex > 0 && (
          <div className={styles.prevArrow} onClick={handlePrev}>
            <IoIosArrowBack />
          </div>
        )}
        <div className={styles.divsedesCont}>
          {projetos
            .slice(currentIndex, currentIndex + visibleItems)
            .map((projeto, index) => (
              <div key={index} className={styles.divcropContainer}>
                <div
                  className={styles.divcrop}
                  onClick={() => openModal(currentIndex + index)}
                >
                  <img
                    className={styles.imagemIcon}
                    alt=""
                    src={projeto.url}
                  />
                </div>
              </div>
            ))}
        </div>
        {currentIndex + visibleItems < projetos.length && (
          <div className={styles.nextArrow} onClick={handleNext}>
            <IoIosArrowForward />
          </div>
        )}
      </div>
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            zIndex: 1003 
          },
          content: {
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "10px",
            borderRadius: "10px",
            boxShadow: "0 0 20px rgba(0, 0, 0, 0.3)",
            width: "80%",
            height:"90%",
            maxWidth: "800px",
            zIndex: 1003,
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            flexDirection:"column",
            borderBottom:"4px solid red"
          }
        }}
        overlayClassName={styles.modalOverlay}
      >
        <span className={styles.close} onClick={closeModal}>
          &times;
        </span>
        {selectedProject && (
          <Carousel
            showArrows={true}
            selectedItem={selectedProjectIndex}
            onChange={(index) => setSelectedProjectIndex(index)}
            className={styles.carousel} 
          >
            {projetos.map((projeto, index) => (
              <div key={index}>
                <img src={projeto.url} alt="" className={styles.carouselImage} />
                <p>{projeto.description}</p>
              </div>
            ))}
          </Carousel>
        )}
      </Modal>
    </div>
  );
};

export default Projetos;
