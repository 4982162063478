import React, { useState, useEffect } from "react";
import styles from "./ProjetosGeniais.module.css";

import img1 from "../../ASSETS/Projetos e serviços/Serviço 1.png";
import img2 from "../../ASSETS/Projetos e serviços/Serviço 2.png";
import img3 from "../../ASSETS/Projetos e serviços/Serviço 3.png";
import img4 from "../../ASSETS/Projetos e serviços/Serviço 4.png";
import img5 from "../../ASSETS/Projetos e serviços/Serviço 5.png";
import img6 from "../../ASSETS/Projetos e serviços/Serviço 6.png";

import img7 from "../../ASSETS/Projetos e serviços/projetos/Projeto Amigo da Escola.png";
import img8 from "../../ASSETS/Projetos e serviços/projetos/Projeto Aprender Brincando.png";
import img9 from "../../ASSETS/Projetos e serviços/projetos/Projeto Ciranda de Leitura.png";
import img10 from "../../ASSETS/Projetos e serviços/projetos/Projeto Cirando Arte.png";
import img11 from "../../ASSETS/Projetos e serviços/projetos/Projeto Cuidando do Planeta.png";
import img12 from "../../ASSETS/Projetos e serviços/projetos/Projeto Eu sou o Escritor.png";
import img13 from "../../ASSETS/Projetos e serviços/projetos/Projeto Feira das Profissões.png";
import img14 from "../../ASSETS/Projetos e serviços/projetos/Projeto Inglês Musicalizado.png";
import img15 from "../../ASSETS/Projetos e serviços/projetos/Projeto Iniciação Científica.png";
import img16 from "../../ASSETS/Projetos e serviços/projetos/Projeto Musicalização Infantil.png";
import img17 from "../../ASSETS/Projetos e serviços/projetos/Projeto Recreação Aquática.png";
import img18 from "../../ASSETS/Projetos e serviços/projetos/Projeto Saúde e Alimentação Saudável.png";
import img19 from "../../ASSETS/Projetos e serviços/projetos/Projeto Teatro Encantado.png";

import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Modal from "react-modal";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const ProjetosGeniais = () => {
  const projetos = [
    { id: 1, url: img1 },
    { id: 2, url: img2 },
    { id: 3, url: img3 },
    { id: 4, url: img4 },
    { id: 5, url: img5 },
    { id: 6, url: img6 },
    { id: 7, url: img7 },
    { id: 8, url: img8 },
    { id: 9, url: img9 },
    { id: 10, url: img10 },
    { id: 11, url: img11 },
    { id: 12, url: img12 },
    { id: 13, url: img13 },
    { id: 14, url: img14 },
    { id: 15, url: img15 },
    { id: 16, url: img16 },
    { id: 17, url: img17 },
    { id: 18, url: img18 },
    { id: 19, url: img19 },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleItems, setVisibleItems] = useState(4);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProjectIndex, setSelectedProjectIndex] = useState(null);

  useEffect(() => {
    const updateVisibleItems = () => {
      if (window.innerWidth < 640) setVisibleItems(1);
      else if (window.innerWidth < 800) setVisibleItems(2);
      else if (window.innerWidth < 1100) setVisibleItems(3);
      else setVisibleItems(4);
    };

    updateVisibleItems();
    window.addEventListener("resize", updateVisibleItems);
    return () => window.removeEventListener("resize", updateVisibleItems);
  }, []);

  const handlePrev = () => setCurrentIndex((prev) => Math.max(prev - visibleItems, 0));
  const handleNext = () => setCurrentIndex((prev) => Math.min(prev + visibleItems, projetos.length - visibleItems));
  const openModal = (index) => {
    setSelectedProjectIndex(index);
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
    setSelectedProjectIndex(null);
  };

  const selectedProject = projetos[selectedProjectIndex];

  return (
    <section className={styles.errorHandler}>
      <div className={styles.ele1Parent}>
        <img className={styles.ele1Icon} loading="lazy" alt="" src="/ele-1@2x.png" />
        <div className={styles.estruturaParent}>
          <h3>Nossos Serviços e Projetos</h3>
          <div className={styles.divsedesContWrapper}>
            {currentIndex > 0 && <div className={styles.prevArrow} onClick={handlePrev}><IoIosArrowBack /></div>}
            <div className={styles.divsedesCont}>
              {projetos.slice(currentIndex, currentIndex + visibleItems).map((projeto, index) => (
                <div key={index} className={styles.divcropContainer}>
                  <div className={styles.divcrop} onClick={() => openModal(currentIndex + index)}>
                    <img className={styles.imagemIcon} alt="" src={projeto.url} />
                  </div>
                </div>
              ))}
            </div>
            {currentIndex + visibleItems < projetos.length && <div className={styles.nextArrow} onClick={handleNext}><IoIosArrowForward /></div>}
          </div>
          <Modal
            isOpen={modalOpen}
            onRequestClose={closeModal}
            style={{
              overlay: { zIndex: 1003 },
              content: {
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                padding: "10px",
                borderRadius: "10px",
                boxShadow: "0 0 20px rgba(0, 0, 0, 0.3)",
                width: "80%",
                height: "90%",
                maxWidth: "800px",
                zIndex: 1003,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                borderBottom: "4px solid red",
              }
            }}
            overlayClassName={styles.modalOverlay}
          >
            <span className={styles.close} onClick={closeModal}>&times;</span>
            {selectedProject && (
              <Carousel
                showArrows={true}
                selectedItem={selectedProjectIndex}
                onChange={(index) => setSelectedProjectIndex(index)}
                className={styles.carousel}
              >
                {projetos.map((projeto, index) => (
                  <div key={index}>
                    <img src={projeto.url} alt="" className={styles.carouselImage} />
                  </div>
                ))}
              </Carousel>
            )}
          </Modal>
        </div>
      </div>
    </section>
  );
};

export default ProjetosGeniais;
