import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import TopAreFooter from "./Footer/TopAreaFooter"
import FrameComponent from "./Footer/BottomAreaFooter";
import styles from "./OutputFormatter.module.css";

const OutputFormatter = () => {
  const navigate = useNavigate();

  const onNOVALOGO1Click = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onItemContainerClick = useCallback(() => {
    navigate("/macbook-air-4");
  }, [navigate]);

  return (
    <section className={styles.outputFormatter}>
      <footer className={styles.footer}>
        <div className={styles.linkSvg} />
        <div className={styles.errorDetection}>
          <TopAreFooter
            propPadding="0px var(--padding-18xl)"
            onNOVALOGO11Click={onNOVALOGO1Click}
            onItemContainerClick={onItemContainerClick}
          />
          <div className={styles.divfooterLine} />
        </div>
        <FrameComponent
          propDebugCommit="unset"
          propDebugCommit1="unset"
          propDebugCommit2="unset"
          propDebugCommit3="unset"
        />
      </footer>
      <div className={styles.footer1}>
        <div className={styles.todosOsDireitos}>
          Todos os direitos reservados.
        </div>
        <div className={styles.linkPoltica}>Política de Confidencialidade</div>
      </div>
    </section>
  );
};

export default OutputFormatter;
