import React, { useState, useEffect } from 'react';
import styles from './TrabalheConosco.module.css';
import img from "../ASSETS/trabalherconosco/trabalhe_conosco.png";
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import emailjs from 'emailjs-com';
import { storage } from '../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';

const TrabalheConosco = () => {
  const [file, setFile] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const { width, height } = useWindowSize();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const uploadToFirebase = async (file) => {
    const storageRef = ref(storage, `resumes/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    try {
      await new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          null,
          (error) => reject(error),
          () => resolve()
        );
      });
      const downloadURL = await getDownloadURL(storageRef);
      return downloadURL;
    } catch (error) {
      console.error('Erro ao fazer upload para o Firebase:', error);
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let resumeLink = 'Nenhum currículo anexado';
      if (file) {
        resumeLink = await uploadToFirebase(file);
      }

      // Enviar e-mail com dados do formulário
      await emailjs.send('service_t9l8ign', 'template_z8pzv9e', {
        to_name: 'Colégio Alternativo Colaço',
        from_name: e.target.name.value,
        from_email: e.target._replyto.value,
        message: e.target.message.value,
        phone: e.target.phone.value,
        resume_url: resumeLink,
      }, 'Mu_S4P9Iunyuc0DuK');

      setShowConfetti(true);
      setSubmitted(true);
      window.scrollTo(0, 0); // Colocar o scroll no topo

      // Desativar confetes após 10 segundos
      setTimeout(() => {
        setShowConfetti(false);
      }, 10000);
    } catch (error) {
      console.error('Erro ao enviar o formulário:', error);
    }
  };

  if (submitted) {
    return (
      <div className={styles.macbookAir1}>
        {showConfetti && <Confetti width={width} height={height} />}
        <Header />
        <section className={styles.EsportesSection}>
          <div className={styles.EsportesFull}>
            <h1>Obrigado!</h1>
            <p>Seu formulário foi enviado com sucesso.🎊🎊 Entraremos em contato em breve.</p>
          </div>
        </section>
        <Footer />
      </div>
    );
  }

  return (
    <div className={styles.macbookAir1}>
      <Header />
      <section className={styles.EsportesSection}>
        <div className={styles.EsportesFull}>
          <h1>Trabalhe conosco</h1>
          <hr />
          <section className={styles.rectangleParent}>
            <form onSubmit={handleSubmit} className={styles.form}>
              <div className={styles.rectangle} />
              <div className={styles.rectangleGroup}>
                <div className={styles.rectangle1} />
                <div className={styles.dataFlowController}>
                  <h3 className={styles.entreEmContato}>Entre em contato</h3>
                  <div className={styles.preenchaOFormulrio}>
                    Preencha o formulário abaixo para entrar em contato:
                  </div>
                </div>
                <div className={styles.conditionalBranch}>
                  <div className={styles.elsePathway}>
                    <div className={styles.frameParent}>
                      <div className={styles.nomeParent}>
                        <div className={styles.nome}>Nome*</div>
                        <input
                          className={styles.breadthFirstSearch}
                          type="text"
                          name="name"
                          required
                        />
                      </div>
                    </div>
                    <div className={styles.emailParent}>
                      <div className={styles.email}>Email*</div>
                      <input
                        className={styles.breadthFirstSearch}
                        type="email"
                        name="_replyto"
                        required
                      />
                    </div>
                  </div>
                  <div className={styles.phoneParent} style={{ width: "100%" }}>
                    <div className={styles.phone}>Telefone</div>
                    <input
                      className={styles.breadthFirstSearch}
                      type="tel"
                      name="phone"
                      style={{ width: "93%" }}
                    />
                  </div>
                  <div className={styles.rectangleContainer}>
                    <div className={styles.phone}>Mensagem:</div>
                    <textarea
                      style={{ height: "130px" }}
                      className={styles.breadthFirstSearch}
                      name="message"
                      required
                    />
                  </div>
                  <div className={styles.curriculoParent}>
                    <div className={styles.curriculo}>Anexar Currículo:</div>
                    <input
                      type="file"
                      onChange={handleFileChange}
                      accept=".pdf,.doc,.docx"
                      className={styles.breadthFirstSearch}
                    />
                  </div>
                  <button type="submit" className={styles.keyValuePair}>
                    <div className={styles.rectangle3} />
                    <div className={styles.enviar}>Enviar</div>
                  </button>
                </div>
              </div>
            </form>
            <div className={styles.image23Wrapper}>
              <img
                className={styles.image23Icon}
                loading="lazy"
                alt=""
                src={img}
              />
            </div>
          </section>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default TrabalheConosco;
