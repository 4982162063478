import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import FrameComponent11 from "../components/FrameComponent11";
import Projetos from "../components/ProjetosGeniais/Projetos";
import styles from "./Estrutura.module.css";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import img1 from "../ASSETS/Estrutura/img1.jpg"
import img2 from "../ASSETS/Estrutura/img2.jpg"
import img3 from "../ASSETS/Estrutura/img3.jpg"
import img4 from "../ASSETS/Estrutura/img4.jpg"
import img5 from "../ASSETS/Estrutura/img5.jpg"
import img6 from "../ASSETS/Estrutura/img6.jpg"
import img7 from "../ASSETS/Estrutura/img7.jpg"
import img8 from "../ASSETS/Estrutura/img8.jpg"
import img9 from "../ASSETS/Estrutura/img9.jpg"
import img10 from "../ASSETS/Estrutura/img10.jpg"
import img11 from "../ASSETS/Estrutura/img11.jpg"
import img12 from "../ASSETS/Estrutura/img12.jpg"
import img13 from "../ASSETS/Estrutura/img13.jpg"
import img14 from "../ASSETS/Estrutura/img14.jpg"
import img15 from "../ASSETS/Estrutura/img15.jpg"
import img16 from "../ASSETS/Estrutura/img16.jpg"
import img17 from "../ASSETS/Estrutura/img17.jpg"

const MacBookAir4 = () => {
  const navigate = useNavigate();

  const onNOVALOGO2Click = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const projetos = [
    {
      id: 1,
      url: img1 
    },
    {
      id: 2,
      url: img2
    },
    {
      id: 3,
      url: img3 
    },
    {
      id: 4,
      url: img4 
    },
    {
      id: 5,
      url: img5 
    },
    {
      id: 6,
      url: img6 
    },
    {
      id: 7,
      url: img7 
    },
    {
      id: 8,
      url: img8
    },
    {
      id: 9,
      url: img9
    },
    {
      id: 10,
      url: img10
    },
    {
      id: 11,
      url: img11
    },
    {
      id: 12,
      url: img12
    },
    {
      id: 13,
      url: img13
    },
    {
      id: 14,
      url: img14
    },
    {
      id: 15,
      url: img15
    },
    {
      id: 16,
      url: img16
    },
    {
      id: 17,
      url: img17
    },
  ];

  return (
    <div className={styles.macbookAir5}>
      <Header />
      <div className={styles.frameParent}>
        <div className={styles.frameGroup}>
          <div className={styles.frameWrapper}>
            <FrameComponent11 onNOVALOGO2Click={onNOVALOGO2Click} />
          </div>
        </div>
      </div>
      <div className={styles.lineParent}>
        <div className={styles.frameChild} />
        <div className={styles.frameContainer}>
        <Projetos
          estrutura="Nossa Estrutura"
          propWidth="949px"
          propPadding="unset"
          propWidth1="256px"
          propPaddingRight="unset"
          frameDivMinWidth="949px"
          frameDivDebugCommit="unset"
          projetos={projetos} // Passando a matriz de objetos para o componente Projetos
        />
        </div>
      </div>
      <Footer />
    </div>
  );
};


export default MacBookAir4;
