import React from 'react';
import styles from "./GroupComponent.module.css";

const GroupComponent = () => {
  return (
    <section className={styles.rectangleParent}>
      <form
        className={styles.form}
        action="https://formspree.io/f/xzbnjkzy"
        method="POST"
      >
        <div className={styles.rectangle} />
        <div className={styles.rectangleGroup}>
          <div className={styles.rectangle1} />
          <div className={styles.dataFlowController}>
            <h3 className={styles.entreEmContato}>Entre em contato</h3>
            <div className={styles.preenchaOFormulrio}>
              Preencha o formulário abaixo para entrar em contato:
            </div>
          </div>
          <div className={styles.conditionalBranch}>
            <div className={styles.elsePathway}>
              <div className={styles.frameParent}>
                <div className={styles.nomeParent}>
                  <div className={styles.nome}>Nome*</div>
                  <input
                    className={styles.breadthFirstSearch}
                    type="text"
                    name="name" // Campo de nome correspondente ao esperado pelo Formspree
                    required
                  />
                </div>
              </div>
              <div className={styles.emailParent}>
                <div className={styles.email}>Email*</div>
                <input
                  className={styles.breadthFirstSearch}
                  type="email"
                  name="_replyto" // Campo de e-mail correspondente ao esperado pelo Formspree
                  required
                />
              </div>
            </div>
            <div className={styles.phoneParent} 
                style={{width:"100%"}}>
              <div className={styles.phone}>Telefone</div>
              <input
                className={styles.breadthFirstSearch}
                type="tel"
                name="phone" // Campo de telefone
                style={{width:"93%"}}
              />
            </div>
            <div className={styles.rectangleContainer}>
              <div className={styles.phone}>Mensagem:</div>
              <textarea
                style={{ height: "130px" }}
                className={styles.breadthFirstSearch}
                name="message" // Campo de mensagem correspondente ao esperado pelo Formspree
                required
              />
              <button type="submit" className={styles.keyValuePair}>
                <div className={styles.rectangle3} />
                <div className={styles.enviar}>Enviar</div>
              </button>
            </div>
          </div>
        </div>
      </form>
      <div className={styles.image23Wrapper}>
        <img
          className={styles.image23Icon}
          loading="lazy"
          alt=""
          src="/image-23@2x.png"
        />
      </div>
    </section>
  );
};

export default GroupComponent;
