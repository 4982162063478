import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./MacBookAir3.module.css";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";

const MacBookAir3 = () => {
  const navigate = useNavigate();

  const onNOVALOGO1Click = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onListItemLinkClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onListItem5Click = useCallback(() => {
    navigate("/macbook-air-5");
  }, [navigate]);

  const onListItem7Click = useCallback(() => {
    navigate("/macbook-air-2");
  }, [navigate]);

  const onListItem9Click = useCallback(() => {
    navigate("/macbook-air-3");
  }, [navigate]);

  const onListItem10Click = useCallback(() => {
    navigate("/macbook-air-5");
  }, [navigate]);

  const onListItem12Click = useCallback(() => {
    navigate("/macbook-air-2");
  }, [navigate]);

  const onListItem13Click = useCallback(() => {
    // Please sync "MacBook Air - 6" to the project
  }, []);

  const onListItem14Click = useCallback(() => {
    navigate("/macbook-air-3");
  }, [navigate]);

  const onVectorIconClick = useCallback(() => {
    // Please sync "MacBook Air - 7" to the project
  }, []);

  const onListItem1Click = useCallback(() => {
    // Please sync "MacBook Air - 7" to the project
  }, []);

  return (
    <div className={styles.macbookAir4}>
      <div className={styles.image13} />
      <div className={styles.image11} />
      <section className={styles.portalLink}>
        <Header />
        <div className={styles.portalLinkInner}>
          <div className={styles.propostaPedaggicaParent}>
            <h1 className={styles.propostaPedaggica}>
              <p className={styles.propostaPedaggica1}>Proposta Pedagógica</p>
            </h1>
            <div className={styles.aPropostaPedaggicaDoColgParent}>
              <div className={styles.aPropostaPedaggicaContainer}>
                <p className={styles.aPropostaPedaggica}>
                  A proposta pedagógica do COLÉGIO ALTERNATIVO COLAÇO é fundamentada nos talentos e direitos de aprendizagem estabelecidos pela BNCC(Base Nacional Comum Curricular), visando o desenvolvimento integral das crianças desde a Educação Infantil até o Ensino Fundamental – Anos Iniciais. Nossa abordagem prioriza seis direitos de aprendizagem: conviver, brincar, participar, explorar, expressar e conhecer-se, que são essenciais para que as crianças tenham uma educação significativa e contextualizada.
                </p>
                <p className={styles.blankLine}>&nbsp;</p>
                <p className={styles.oColgioAlternativo}>
                  No ambiente escolar, buscamos criar situações de aprendizagem que incentivem os alunos a desempenharem um papel ativo, enfrentando desafios e construindo significados sobre si mesmos, os outros e o mundo ao seu redor. Valorizamos as experiências lúdicas na Educação Infantil e a articulação progressiva dessas experiências nos Anos Iniciais do Ensino Fundamental, proporcionando aos alunos novas formas de relacionar-se com o conhecimento e de desenvolver habilidades de leitura, formulação de hipóteses, testagem e elaboração de conclusões.
                </p>
                <p className={styles.aPrimeiraImplicao}>
                Entendemos que cada criança é única e possui potenciais diversos que devem ser explorados e desenvolvidos em um ambiente de aprendizagem satisfatório e construtivo. Nosso compromisso é oferecer uma educação que promova a autonomia, a individualidade, a autoconfiança e a descoberta das aptidões vocacionais de cada aluno, preparando-os para enfrentar os desafios do mundo atual com segurança e competência.
                </p>
                <p className={styles.blankLine1}>&nbsp;</p>
                <p className={styles.blankLine2}>&nbsp;</p>
                <p className={styles.marizaColaoPinheiro}>
                  Mariza Colaço Pinheiro,
                </p>
                <p className={styles.blankLine3}>&nbsp;</p>
                <p className={styles.diretoraPedaggica}>Diretora Pedagógica.</p>
              </div>
              <img
                className={styles.image18Icon}
                loading="lazy"
                alt=""
                src="/image-18@2x.png"
              />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default MacBookAir3;
