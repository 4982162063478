import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';

// Suas credenciais do Firebase
const firebaseConfig = {
    apiKey: "AIzaSyCP_LrCItkOFohH_JwooGwKbQdzNFqCR3k",
    authDomain: "rh-colegio-alternativo-colaco.firebaseapp.com",
    projectId: "rh-colegio-alternativo-colaco",
    storageBucket: "rh-colegio-alternativo-colaco.appspot.com",
    messagingSenderId: "511661150722",
    appId: "1:511661150722:web:94b7c4a3fbc05af8e50d1b",
    measurementId: "G-22ZV3N7KFG"
};

// Inicializar Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export { storage };
