import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Writers.module.css";
import { HiOutlineMenuAlt2, HiX } from "react-icons/hi";
import ModalProjetos from "./ModalProjetos";
import { MdArrowForwardIos } from "react-icons/md";

const Writers = () => {
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [menuOpen, setMenuOpen] = useState(false);
  const [showModalProjetos, setShowModalProjetos] = useState(false);
  const [menuClosing, setMenuClosing] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleMenu = () => {
    if (menuOpen) {
      setMenuClosing(true);
      setTimeout(() => {
        setMenuOpen(false);
        setMenuClosing(false);
      }, 800); // Tempo da animação
    } else {
      setMenuOpen(true);
    }
  };

  const handleCloseAll = () => {
    setMenuOpen(false);
    setShowModalProjetos(false);
  };

  const menuItems = [
    { label: "Estrutura", onClick: () => navigate("/nossaestrutura") },
    { label: "Ensino", onClick: () => navigate("/ensino") },
    { label: "Hino do colégio", onClick: () => navigate("/hino") },
    {
      label: (
        <div className={styles.menuItemWithIcon}>
          <div>Projetos</div> {windowWidth < 900 && <MdArrowForwardIos style={{ paddingTop: "3px", paddingLeft: "3px" }} />}
        </div>
      ), onClick: () => setShowModalProjetos(true)
    },
    { label: "Nossa história", onClick: () => navigate("/nossahistoria") },
    { label: "Esportes", onClick: () => navigate("/esportes") },
  ];

  return (
    <div className={styles.writers}>
      {windowWidth > 800 && (
        <div className={styles.divheaderNewsletter}>
          <a href="https://www.portal-alternativocolaco.com.br">
            <img
              className={styles.ae5fD6cc46238937F5d77d3cfeIcon}
              loading="lazy"
              alt=""
              src="/7788ae5fd6cc46238937f5d77d3cfe79-1@2x.png"
            />
          </a>
        </div>
      )}
      <div className={styles.divheaderMenu}>
        <div className={styles.divheaderMenuContent}>
          <Link to="/" className={styles.menuLink}>
            <img
              className={styles.listItemLink}
              loading="lazy"
              alt=""
              src="/list--item--link.svg"
            />
          </Link>
          {windowWidth <= 900 ? (
            <button onClick={toggleMenu}><HiOutlineMenuAlt2 /></button>
          ) : (
            <div className={styles.divheaderMenuInner}>
              <div className={styles.listItemLinkEnsinoParent}>
                {menuItems.map((item, index) => (
                  <div
                    key={index}
                    className={styles.listItem}
                    onClick={item.onClick}
                  >
                    {item.label}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      {menuOpen && (
        <>
          <div className={`${styles.menuHeadeOpen} ${menuClosing ? 'fade-out' : ''}`}>
            <div onClick={toggleMenu} className={styles.topmenuHeaderOpen}>
              <Link to="/" className={styles.menuLink}>
                <img
                  className={styles.listItemLink}
                  loading="lazy"
                  alt=""
                  src="/list--item--link.svg"
                />
              </Link>
              <div className={styles.closeButton} onClick={toggleMenu}><HiX /></div>
            </div>
            {menuItems.map((item, index) => (
              <div className={styles.menuHeaderOpenItem} key={index} onClick={item.onClick}>
                {item.label}
              </div>
            ))}
          </div>
          <div className={styles.menuHeadeClose} onClick={toggleMenu}>
            ...
          </div>
        </>
      )}
      {showModalProjetos && (
        <ModalProjetos onClose={handleCloseAll} closeProjetos={() => setShowModalProjetos(false)}/>
      )}
    </div>
  );
};

export default Writers;
