import { useMemo } from "react";
import styles from "./BottomAreaFooter.module.css";
import { IoIosArrowForward } from "react-icons/io";

const FrameComponent = ({
  propDebugCommit,
  propDebugCommit1,
  propDebugCommit2,
  propDebugCommit3,
}) => {
  const iconStyle = useMemo(() => {
    return {
      debugCommit: propDebugCommit,
    };
  }, [propDebugCommit]);

  const horrioDeAtendimentoStyle = useMemo(() => {
    return {
      debugCommit: propDebugCommit1,
    };
  }, [propDebugCommit1]);

  const icon1Style = useMemo(() => {
    return {
      debugCommit: propDebugCommit2,
    };
  }, [propDebugCommit2]);

  const maioresInformaes85Style = useMemo(() => {
    return {
      debugCommit: propDebugCommit3,
    };
  }, [propDebugCommit3]);

  return (
    <div className={styles.dataFilterParent}>
      <div className={styles.dataFilter}>
        <div className={styles.dataMapper}>
          <div className={styles.heading3}>Colégio Alternativo colaço</div>
          <div className={styles.doInfantilAoContainer}>
            <p className={styles.doInfantilAo}>Do Infantil ao Fundamental I</p>
            <p className={styles.belaVista}>• Rua Viriato Ribeiro, 724. Bela Vista. Fortaleza-CE</p>
          </div>
        </div>
        <div className={styles.errorCollector}>
          <div className={styles.heading31}>Contato</div>
          <div className={styles.variableModifier}>
            <div className={styles.conditionalOutput}>
              <img
                className={styles.icon}
                alt=""
                src="/icon-1.svg"
                style={iconStyle}
              />
            </div>
            <div
              className={styles.horrioDeAtendimento}
              style={horrioDeAtendimentoStyle}
            >
              Horário de atendimento: segunda a sexta-feira, das 7h às 17h.
            </div>
          </div>
          <div className={styles.variableModifier1}>
            <div className={styles.iconWrapper}>
              <img
                className={styles.icon1}
                alt=""
                src="/icon-2.svg"
                style={icon1Style}
              />
            </div>
            <div
              className={styles.maioresInformaes85}
              style={maioresInformaes85Style}
            >
              Maiores informações: (85) 98806.8633
            </div>
          </div>
        </div>
      </div>
      <a className={styles.button} href="https://www.portal-alternativocolaco.com.br/login">
        <div className={styles.outputMerger}>
          <div className={styles.link}>
            <div className={styles.branchingStructure}>
              <div className={styles.portalAlternativo}>portal Alternativo</div>
            </div>
            <div className={styles.divroundedCircle}>
              <div className={styles.symbol}><IoIosArrowForward /></div>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default FrameComponent;
