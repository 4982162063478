import React from 'react'
import styles from './SubPagesEsportes.module.css'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import img1 from '../../ASSETS/Esportes/natacao/036e08a3-9e2e-40ae-b5d5-686fbcd528ef.jpg'
import img2 from '../../ASSETS/Esportes/natacao/75e64cd6-b5e9-4355-a3c7-666e47d24152.jpg'
import img3 from '../../ASSETS/Esportes/natacao/d4bff038-9814-49b5-86cb-44567ae74317.jpg'

import professorimg from '../../ASSETS/Esportes/natacao/3e252d3b-2652-410c-b931-90f6cf5e988e.jpg'
import Projetos from '../../components/ProjetosGeniais/Projetos'

const NataçãoPage = () => {
    const projetos = [
        {
            id: 1,
            url: img1
        },
        {
            id: 2,
            url: img2
        },
        {
            id: 3,
            url: img3
        },
        {
            id: 4,
            url: img1
        },
        {
            id: 5,
            url: img2
        },
    ];

    return (
        <div className={styles.macbookAir1}>
            <Header />
            <section className={styles.EsportesSection}>
                <div className={styles.EsportesFull}>
                    <h1>Natação</h1>
                    <div className={styles.ConteudoFull}>
                        <div>
                            <text>
                                A natação infantil é superimportante para os pequenos! Além de ser uma atividade superdivertida, ela traz uma tonelada de benefícios. Primeiro, é uma excelente forma de exercício físico, ajudando no desenvolvimento da coordenação motora, força muscular e resistência. Também é ótima para a saúde respiratória e cardiovascular.

                                Outra coisa legal é que a natação ensina habilidades de segurança na água, o que é fundamental para a segurança das crianças. Elas aprendem a se sentir confortáveis e confiantes na água, o que pode ser crucial em situações de emergência. E não podemos esquecer o aspecto social: na natação, os pequenos fazem amigos, aprendem a trabalhar em equipe e a respeitar as regras.

                                Resumindo, a natação é uma atividade completa que traz benefícios físicos, mentais e sociais para as crianças, além de ser uma diversão só!
                            </text>
                        </div>
                        <div className={styles.imgProfessor}>
                            <div>
                                <img src={professorimg} />
                            </div>
                            <p>Aline Teixeira Educadora Física.</p>
                        </div>
                    </div>
                    <hr />
                    <Projetos
                        estrutura="Nossa Estrutura"
                        propWidth="949px"
                        propPadding="unset"
                        propWidth1="256px"
                        propPaddingRight="unset"
                        frameDivMinWidth="949px"
                        frameDivDebugCommit="unset"
                        projetos={projetos}
                    />
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default NataçãoPage