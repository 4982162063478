import styles from "./FlowControl.module.css";

const FlowControl = () => {
  return (
    <section className={styles.flowControl}>
      <div className={styles.processingUnit} />
      <div className={styles.connectionHub}>
        <div className={styles.dataFilterParent}>
          <div className={styles.dataFilter}>
            <div className={styles.rectangleParent}>
              <div className={styles.frameChild} />
              <img
                className={styles.image17Icon}
                loading="lazy"
                alt=""
                src="/image-17@2x.png"
              />
              <div className={styles.errorHandler}>
                <div className={styles.marizaColaoPinheiro}>
                  Mariza Colaço Pinheiro fundadora e diretora
                </div>
              </div>
            </div>
          </div>
          <div className={styles.databaseLink}>
            <h1 className={styles.conheaAHistria}>
              HOMENAGEM DE ANIVERSÁRIO
            </h1>
            <div className={styles.oColgioAlternativoColaoContainer}>
              <p className={styles.oColgioAlternativoColao}>
                O Colégio Alternativo Colaço esse ano de 2024 está fazendo 28 anos de funcionamento.
                Durante essa trajetória, passaram muitos alunos e educadores  por aqui e criaram laços duradouros, fazendo para sempre  parte da nossa história.

                Acreditamos que tudo que fazemos com amor e dedicação fica marcado na vida das pessoas.

                A cada ano, a cada ideia nova e a cada projeto  implantado nos sentimos  renovados.

                É muito gratificante estar recebendo em nossa escola os filhos de ex alunos .
              </p>
              <p className={styles.naRuaViriato}>
                Sentimo-nos gratificados por cada aluno formado e que está seguindo sua trajetória com todos os valores e saberes apreendidos durante todos esses anos e agora repassam para seus filhos.
                Nossa identidade exala arte,  criatividade e fé  em um mundo melhor representado por uma geração  que recebeu conhecimento junto com muito amor e arte!
              </p>
              <p className={styles.assimATeoria}>
                Nosso slogan "Há 28 anos construindo laços e saberes" representa o coração da nossa história.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FlowControl;
