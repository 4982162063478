import React, { useState, useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import MacBookAir1 from "./pages/pagehino";
import MacBookAir2 from "./pages/MacBookAir2";
import MacBookAir3 from "./pages/MacBookAir3";
import MacBookAir4 from "./pages/Estrutura";
import PageEsportes from "./pages/PageEsportes";
import BalePage from "./pages/SubPagesEsportes/BalePage";
import KaratePage from "./pages/SubPagesEsportes/KaratePage";
import NataçãoPage from "./pages/SubPagesEsportes/NataçãoPage";
import ProjetoTeatro from "./pages/ProjetoTeatro";
import ProjetoAlimentacaoSaldavel from "./pages/ProjetoAlimentacaoSaldavel";
import Ensino from "./pages/Ensino";
import ProjetoEuSouOEscritor from "./pages/ProjetoEuSouOEscritor";

import AcceptCookies from "./components/AcceptCookies/AcceptCookies";
import ProjetoCuidandoDoPlaneta from "./pages/ProjetoCuidandoDoPlaneta";
import ProjetoAmigodaEscola from "./pages/ProjetoAmigodaEscola";
import ProjetoCriandoArte from "./pages/ProjetoCriandoArte";
import ProjetoMusicalização from "./pages/ProjetoMusicalização";
import TrabalheConosco from "./pages/TrabalheConosco";
import ProjetoIniciacaoCientifica from "./pages/ProjetoIniciacaoCientifica";
import ProjetoEmergenciaMedica from "./pages/ProjetoEmergenciaMedica";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  const [showAcceptCookies, setShowAcceptCookies] = useState(false);

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/hino":
        title = "";
        metaDescription = "";
        break;
      case "/nossahistoria":
        title = "";
        metaDescription = "";
        break;
      case "/propostapedagogica":
        title = "";
        metaDescription = "";
        break;
      case "/nossaestrutura":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");
    if (!cookiesAccepted) {
      setShowAcceptCookies(true);
    }
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem("cookiesAccepted", "true");
    setShowAcceptCookies(false);
  };

  return (
    <>
      {showAcceptCookies && <AcceptCookies onAccept={handleAcceptCookies} />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/hino" element={<MacBookAir1 />} />
        <Route path="/nossahistoria" element={<MacBookAir2 />} />
        <Route path="/propostapedagogica" element={<MacBookAir3 />} />
        <Route path="/nossaestrutura" element={<MacBookAir4 />} />
        <Route path="/esportes" element={<PageEsportes />} />
        <Route path="/esportes/ballet" element={<BalePage />} />
        <Route path="/esportes/natacao" element={<NataçãoPage />} />
        <Route path="/esportes/karate" element={<KaratePage />} />
        <Route path="/teatro" element={<ProjetoTeatro />} />
        <Route path="/alimentacaosaudavel" element={<ProjetoAlimentacaoSaldavel />} />
        <Route path="/ensino" element={<Ensino />} />
        <Route path="/projetoeusouoescritor" element={<ProjetoEuSouOEscritor />} />
        <Route path="/projetocuidandodoplaneta" element={<ProjetoCuidandoDoPlaneta />} />
        <Route path="/projetoamigodaescola" element={<ProjetoAmigodaEscola />} />
        <Route path="/projetocriandoarte" element={<ProjetoCriandoArte />} />
        <Route path="/projetomusicalizacao" element={<ProjetoMusicalização />} />
        <Route path="/projetoiniciacaocientifica" element={<ProjetoIniciacaoCientifica />} />
        <Route path="/trabalheconosco" element={<TrabalheConosco />} />
        <Route path="/projetoemergenciamedica" element={<ProjetoEmergenciaMedica />} />
      </Routes>
    </>
  );
}

export default App;
