// src/components/DivswiperContainer.js
import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Pagination, Autoplay } from 'swiper/modules';

import styles from "./DivswiperContainer.module.css";
import img1 from "../ASSETS/MainImg/matriculas abertas.jpg"; 
import img2 from "../ASSETS/MainImg/cuidando do planeta.jpg";
import img3 from "../ASSETS/MainImg/dia dos avos.jpg";
import img4 from "../ASSETS/MainImg/eu sou o escritor.jpg";
import img5 from "../ASSETS/MainImg/nutricional.jpg";
import img6 from "../ASSETS/MainImg/teatro.jpg";
import img7 from "../ASSETS/MainImg/amigo da escola.jpg";
import img8 from "../ASSETS/MainImg/recreacao_aquatica.jpg";
import img9 from "../ASSETS/MainImg/alimentacao_saldavel.jpg";
import img10 from "../ASSETS/MainImg/autor_do_mes.jpg";




const DivswiperContainer = () => {
  const images = [img1, img2, img3,img4,img5,img6,img7,img8,img9,img10];

  return (
    <section className={styles.divswiperContainer}>
      <Swiper
        spaceBetween={30}
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000 }}
        modules={[Pagination, Autoplay]}
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <div className={styles.iconParent}>
              <img className={styles.image1Icon} alt={`Slide ${index}`} src={image} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default DivswiperContainer;
