import { useEffect, useState } from "react";
import styles from "./SocialMedia.module.css";
import { LuYoutube } from "react-icons/lu";

const SocialMedia = () => {

  return (
    <section className={styles.frameParent}>
      <div className={styles.appRootAppInicioWrapper}>
        <div className={styles.appRootAppInicio}>
          <div className={styles.instagramParent}>
            <div className={styles.instagram}>Instagram</div>
            <div className={styles.faIconImgWrapper}>
              <img className={styles.faIconImg} alt="" src="/faicon--img.svg" />
            </div>
          </div>
          {/* Instagran */}
          <div className={styles.appRootAppInicioInner}>
            <div className={styles.instagramwebpParent}>
              <iframe
                src="https://www.instagram.com/p/C3m2R5LsajN/embed/"
                width="500"
                height="600"
                frameBorder="0"
                scrolling="no"
                allowTransparency="true"
              ></iframe>
              <div className={styles.wrapper}>
                <div className={styles.div}>'</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Youtube */}
      <div className={styles.appRootAppInicioContainer}>
        <div className={styles.appRootAppInicio1}>
          <div className={styles.novaLogo1Wrapper}>
            <img
              className={styles.novaLogo1}
              alt=""
              src="/nova-logo-1-1@2x.png"
            />
            <div className={styles.novaLogo1youtube}> <LuYoutube style={{fontSize:"30px"}}/>Youtube </div>
          </div>
          <div className={styles.body}>
            <iframe
              title="latest-video"
              width="500"
              height="300"
              src={`https://www.youtube.com/embed/rnBh8gaGKvc`}
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
      {/* Boneco Alternativo */}
      <div className={styles.branchConnection}>
        <div>
          <img
            className={styles.ela1Icon}
            loading="lazy"
            alt=""
            src="/ela-1@2x.png"
          />
        </div>
      </div>
    </section>
  );
};

export default SocialMedia;
