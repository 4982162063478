import DataStructures from "./DataStructures";
import styles from "./HeaderBottom.module.css";
import Writers from "./Writers";

const HeaderBottom = () => {
  return (
    <section className={styles.algorithms}>
      <DataStructures />
      <Writers />
    </section>
  );
};

export default HeaderBottom;
