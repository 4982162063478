import styles from "./FrameComponent7.module.css";

const FrameComponent7 = () => {
  return (
    <div className={styles.frameWrapper}>
      <div className={styles.nossaHistriaParent}>
        <h1 className={styles.nossaHistria}>Nossa História</h1>
        <div className={styles.oColgioAlternativoFoiFundParent}>
          <div className={styles.oColgioAlternativoContainer}>
            <p className={styles.oColgioAlternativo}>
              O Colégio Alternativo Colaço foi fundado em 10 de novembro de 1996 pela professora Mariza Colaço Pinheiro, recém-formada em Letras pela UECE (Universidade Estadual do Ceará) naquela época. Hoje, pós-graduada em Administração Escolar, ela iniciou essa jornada junto com suas irmãs Ana Colaço Pinheiro, Felícia Colaço Pinheiro e Maria Michele Colaço Pinheiro. Inicialmente denominada como "Alternativo, Centro Integrado de Ensino", a instituição começou suas atividades em um prédio alugado na Rua Viriato Ribeiro, 614, Bela Vista, acolhendo exclusivamente alunos da Educação Infantil.
            </p>
            <p className={styles.precipuamenteAIdeia}>
              A inspiração para abrir o Colégio surgiu principalmente da mãe, Maria Marinete Colaço Pinheiro, uma alfabetizadora dedicada que sempre teve afinidade com o trabalho com crianças. Hoje conhecida como a vovó do Colégio, ela continua a acolher as crianças, fazendo-as se sentirem apoiadas e parte da família Alternativo.
            </p>
            <p className={styles.emNovembroDe}>
              Em novembro de 1998, o Colégio Alternativo cresceu e ganhou sede
              própria, na Rua Viriato Ribeiro, 724, conquistando autorização
              para funcionamento do Ensino Fundamental.
            </p>
            <p className={styles.posteriormenteEm2003}>
              Em novembro de 1998, o Colégio Alternativo expandiu-se e adquiriu sua própria sede na Rua Viriato Ribeiro, 724, obtendo autorização para oferecer o Ensino Fundamental.
            </p>
            <p className={styles.atualmenteOColgio}>
              Em seguida, em 2003, o Colégio passou por outra ampliação significativa, ocupando o dobro do espaço ao adquirir o 1º andar do prédio. Em janeiro de 2004, recebeu uma nova autorização e passou a ser conhecido como Colégio Alternativo, especializando-se no atendimento em período integral.
            </p>
            <p className={styles.atualmenteOColgio}>
              Atualmente, o Colégio Alternativo Colaço recebe alunos desde a fase Infantil I até o 5º Ano do Ensino Fundamental I, oferecendo modalidades em meio período e em tempo integral.
              Em 2024, passamos por uma nova ampliação significativa, adicionando mais salas especialmente destinadas às creches. Essa expansão reflete o compromisso contínuo da instituição em oferecer um ambiente educacional abrangente e de qualidade, atendendo às necessidades específicas das crianças em suas primeiras etapas de aprendizado.
            </p>
          </div>
          <div className={styles.dataAggregator}>
            <div className={styles.logicGate}>
              <img
                className={styles.image16Icon}
                loading="lazy"
                alt=""
                src="/image-16@2x.png"
              />
              <div className={styles.outputCollector}>
                <div className={styles.famliaColaoFundadora}>
                  Família Colaço, fundadora do Colégio Alternativo Colaço.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrameComponent7;
