import React from 'react'

import styles from './SubPagesEsportes.module.css'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'

import img1 from '../../ASSETS/Esportes/karate/bce52b9c-1703-4a03-be23-a7aac975ef89.jpg'
import img2 from '../../ASSETS/Esportes/karate/384bacab-6ffb-4ef8-9ce6-4056000e54a6.jpg'
import img3 from '../../ASSETS/Esportes/karate/aed03b01-aa54-4a5d-a06b-1fdf52980b6e.jpg'
import img4 from '../../ASSETS/Esportes/karate/b17b2e74-9cfd-44ae-a689-8681eedd69ae.jpg'
import img5 from '../../ASSETS/Esportes/karate/81516b84-13f7-4910-9ffb-a83760059e41.jpg'

import professorimg from '../../ASSETS/Esportes/karate/c0d31d93-d43f-48cf-a30f-66de386ab537.jpg'
import Projetos from '../../components/ProjetosGeniais/Projetos'

const KaratePage = () => {
    const projetos = [
        {
            id: 1,
            url: img1
        },
        {
            id: 2,
            url: img2
        },
        {
            id: 3,
            url: img3
        },
        {
            id: 4,
            url: img4
        },
        {
            id: 5,
            url: img5
        },
    ];
    return (
        <div className={styles.macbookAir1}>
            <Header />
            <section className={styles.EsportesSection}>
                <div className={styles.EsportesFull}>
                    <h1>karatê</h1>
                    <div className={styles.ConteudoFull}>
                        <div>
                            <text>
                                O Karatê Infantil é muito mais do que aprender movimentos e técnicas de defesa pessoal. É uma jornada de autodescoberta, disciplina e respeito, moldando não apenas corpos fortes, mas também mentes equilibradas e corações corajosos.

                                Nas aulas, os pequenos guerreiros aprendem não apenas a importância da concentração e da prática constante, mas também valores fundamentais como o respeito pelo mestre e colegas, a humildade na vitória e a resiliência na derrota.

                                Cada golpe, cada defesa, cada momento de superação é uma oportunidade de crescimento e aprendizado. No Karatê Infantil, as crianças desenvolvem não apenas habilidades físicas, mas também fortalecem sua confiança, autocontrole e capacidade de enfrentar desafios com determinação e coragem.

                                Mais do que um esporte, o Karatê Infantil é uma escola de vida, preparando os pequenos para os desafios do presente e do futuro, enquanto cultivam valores essenciais que os acompanharão ao longo de suas jornadas.
                            </text>
                        </div>
                        <div className={styles.imgProfessor}>
                            <div>
                                <img src={professorimg} />
                            </div>
                            <p>SENSEI ESPEDITO, FAIXA PRETA QUARTO GRAU QUE HÁ OITO ANOS LECIONANDO EM NOSSA ESCOLA, COM EXCELENTES RESULTADOS.</p>
                        </div>
                    </div>
                    <hr />
                    <Projetos
                        estrutura="Nossa Estrutura"
                        propWidth="949px"
                        propPadding="unset"
                        propWidth1="256px"
                        propPaddingRight="unset"
                        frameDivMinWidth="949px"
                        frameDivDebugCommit="unset"
                        projetos={projetos}
                    />
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default KaratePage