import { Link } from "react-router-dom";
import styles from "./DataStructures.module.css";
import { IoIosArrowForward } from "react-icons/io";
import { CgSearch } from "react-icons/cg";

const DataStructures = ({ onNOVALOGO1Click }) => {
  return (
    <div className={styles.dataStructures}>
      <div className={styles.sorting}>
        <div className={styles.searching}>
          <Link to="/">
            <img
              className={styles.novaLogo1}
              loading="lazy"
              alt=""
              src="/nova-logo-1@2x.png"
              onClick={onNOVALOGO1Click}
            />
          </Link>
          <div className={styles.decryption}>
            <div className={styles.input}>
              <CgSearch style={{fontSize:"19px",fontWeight:"1000",color:"#ed3237"}}/>
              <input
                className={styles.faaSuaBusca}
                placeholder="Faça sua busca"
                type="text"
              />
            </div>
          </div>
        </div>

        <a href="https://www.portal-alternativocolaco.com.br/login">
          <div className={styles.formatters}>
            <div className={styles.link}>
              <div className={styles.transformers}>
                <div className={styles.portalAlternativo}>portal alternativo</div>
              </div>
              <div className={styles.divroundedCircle}>
                <div className={styles.symbol}><IoIosArrowForward /></div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default DataStructures;
