import React from "react";
import styles from "./DataAggregator.module.css";
import { FaSchool } from "react-icons/fa";
import { MdOutlineTaskAlt } from "react-icons/md";
import { GiBigDiamondRing } from "react-icons/gi";
import { Link } from "react-router-dom";


const DataAggregator = () => {

  const items = [
    {
      img: <MdOutlineTaskAlt />,
      title: "Nossa Missão",
      description: "O colégio Alternativo Colaço, acredita numa escola desenvolvedora das competências e habilidades necessárias à criança para ser e estar no mundo.",
      link: "/propostapedagogica"
    },
    {
      img: <GiBigDiamondRing />,
      title: "Compromisso",
      description: "O Colégio Alternativo Colaço se compromete em oferecer uma educação de qualidade, visando o pleno desenvolvimento dos alunos para enfrentar os desafios futuros.",
      link: "/nossahistoria"
    },
    {
      img: <FaSchool />,
      title: "Conheça nossa escola",
      description: "O Colégio Alternativo Colaço oferece uma educação de qualidade, com foco no desenvolvimento individual dos alunos, e conta com uma equipe dedicada ao sucesso de cada estudante.",
      link: "/nossaestrutura"
    },
  ]
  return (
    <section className={styles.NossaEscolaSec}>
      <div className={styles.NossaEscolacontainer}>
        <div className={styles.NossaEscolaTitle}>
          # Nossa Escola
        </div>
        <div className={styles.NossaEscolacontent}>
          {items.map((item) => (
            <Link style={{textDecoration:"none"}} to={item.link}>
              <div className={styles.NossaEscolaitem}>
                <div className={styles.NossaEscolaimg}>
                  {item.img}
                </div>
                <div className={styles.NossaEscolatext}>
                  <div className={styles.title}>{item.title}</div>
                  <div className={styles.description}>{item.description}</div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

export default DataAggregator;
