import React from 'react';
import styles from './ProjetoTeatro.module.css'
import teatroimage from '../ASSETS/Teatro/teatroimage.avif'
import img1 from '../ASSETS/Teatro/dfd0096a-4ad8-45d7-987d-51556ced910b.jpg'
import img2 from '../ASSETS/Teatro/49e62709-55a8-47ee-99b0-a8f7ef710a99.jpg'
import img3 from '../ASSETS/Teatro/301abd5a-c70e-4401-9fda-a0d83b85f32b.jpg'
import img4 from '../ASSETS/Teatro/2fd6ce04-e2ce-4da9-a2dd-18209de981a6.jpg'
import img5 from '../ASSETS/Teatro/29cdd2ab-c3ce-46e4-a26a-7f650e8fa3ae.jpg'
import img6 from '../ASSETS/Teatro/f4ec49a7-170e-4d43-92f2-e9d34cd2e971.jpg'
import img7 from '../ASSETS/Teatro/f632829e-fa83-4047-bf88-1caa1f0177ad.jpg'

import Header from '../components/Header/Header';
import Projetos from '../components/ProjetosGeniais/Projetos';
import Footer from '../components/Footer/Footer';

const ProjetoTeatro = () => {
    const projetos = [
        {
            id: 1,
            url: img6
        },
        {
            id: 2,
            url: img2
        },
        {
            id: 3,
            url: img3
        },
        {
            id: 4,
            url: img4
        },
        {
            id: 5,
            url: img5
        },
        {
            id: 6,
            url: img6
        },
        {
            id: 7,
            url: img7
        },
    ];

    return (
        <div className={styles.macbookAir1}>
            <Header />
            <section className={styles.EsportesSection}>
                <div className={styles.EsportesFull}>
                    <h1>Teatro</h1>
                    <div className={styles.ConteudoFull}>
                        <div>
                            <text>
                                No Colégio Alternativo Colaço, valorizamos a expressão artística e o desenvolvimento criativo dos nossos alunos desde a Educação Infantil até o Ensino Fundamental I. Mensalmente, as apresentações teatrais são organizadas pelos educadores e pelos próprios alunos, proporcionando uma experiência enriquecedora para todos os membros da nossa escola.

                                Nossos alunos têm a oportunidade de explorar suas habilidades teatrais, desde a criação de roteiros até a interpretação de personagens, sob a orientação e coordenação dos educadores. Muitas dessas peças teatrais abordam temas conteudistas, complementando o aprendizado em sala de aula.

                                Além disso, o teatro desempenha um papel fundamental no desenvolvimento da habilidade de falar em público. Durante as apresentações teatrais mensais, os alunos têm a oportunidade de praticar e aprimorar suas habilidades de comunicação oral diante de uma plateia, o que contribui para o desenvolvimento da autoconfiança e da capacidade de se expressar de maneira clara e eficaz.

                                O teatro no Colégio Alternativo Colaço é uma oportunidade valiosa para nossos alunos explorarem sua criatividade, desenvolverem habilidades interpessoais, aprenderem a falar em público e se expressarem de maneira única e significativa, sob a orientação atenciosa dos educadores.
                            </text>
                        </div>
                        <div className={styles.imgProfessor}>
                            <div>
                                <img src={teatroimage} />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <Projetos
                        estrutura="Nosso Projeto"
                        propWidth="949px"
                        propPadding="unset"
                        propWidth1="256px"
                        propPaddingRight="unset"
                        frameDivMinWidth="949px"
                        frameDivDebugCommit="unset"
                        projetos={projetos}
                    />
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default ProjetoTeatro