import React, { useState, useEffect, useRef } from "react";
import styles from "./pagehino.module.css";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import ReactPlayer from "react-player";
import Hino from "../ASSETS/Hino.mp3";

const pagehino = () => {

  return (
    <div className={styles.macbookAir1}>
      <Header />
      <section className={styles.divheaderBarParent}>
        <div className={styles.frameContainer}>
          <div className={styles.hinoDoColgioParent}>
            <h1 className={styles.hinoDoColgio}>Hino do colégio</h1>
            <div className={styles.frameParent}>
              <div className={styles.nossoObjetivoEducaoFazWrapper}>
                <div className={styles.nossoObjetivoContainer}>
                  <p className={styles.nossoObjetivo}>
                    Nosso objetivo é educação,
                  </p>
                  <p className={styles.fazendoDaVida}>
                    Fazendo da vida uma missão
                  </p>
                  <p className={styles.vontadeDeSempre}>
                    Vontade de sempre aprender,
                  </p>
                  <p className={styles.aConstruirUma}>
                    A construir uma justa nação.
                  </p>
                  <p className={styles.blankLine}>&nbsp;</p>
                  <p className={styles.sonhandoJuntosCrescendo}>
                    Sonhando juntos, crescendo juntos,
                  </p>
                  <p className={styles.paraEngrandecerA}>
                    Para engrandecer a Pátria mãe!
                  </p>
                  <p className={styles.ptriaMeBis}>Ó Pátria mãe (bis)</p>
                  <p className={styles.blankLine1}>&nbsp;</p>
                  <p className={styles.futurosGuerreiros}>
                    Futuros guerreiros somos cidadãos.
                  </p>
                  <p className={styles.carregandoNoPeito}>
                    Carregando no peito uma missão,
                  </p>
                  <p className={styles.aprendendoQueA}>
                    Aprendendo que a vida é sempre aprender,
                  </p>
                  <p className={styles.eEnsinar}>
                    E ensinar é propagar nosso saber.
                  </p>
                  <p className={styles.blankLine2}>&nbsp;</p>
                  <p className={styles.abrindoEspaosSempre}>
                    Abrindo espaços sempre ricos,
                  </p>
                  <p className={styles.aprendendoEEnsinando}>
                    Aprendendo e ensinando a viver,
                  </p>
                  <p className={styles.comDireitosE}>
                    Com direitos e deveres aprendidos,
                  </p>
                  <p className={styles.seConquistaO}>
                    Se conquista o direito de vencer.
                  </p>
                  <p className={styles.blankLine3}>&nbsp;</p>
                  <p className={styles.atravsDoSaber}>
                    Através do saber abrem-se as portas,
                  </p>
                  <p className={styles.comLiberdadeE}>
                    Com liberdade e participação,
                  </p>
                  <p className={styles.alternativoNossaOpo}>
                    Alternativo, nossa opção,
                  </p>
                  <p className={styles.paraPromoverA}>
                    Para promover a educação (bis)
                  </p>
                  <p className={styles.blankLine4}>&nbsp;</p>
                  <p
                    className={styles.autorasRaineteColao}
                  >{`Autoras: Rainete Colaço & Soraia Colaço.`}</p>
                  <p className={styles.msicaAdrianoCaula}>
                    Música: Adriano Caçula.
                  </p>
                  <p className={styles.vozMicheleColao}>Voz: Michele Colaço.</p>
                </div>
              </div>
              <div className={styles.frameGroup}>
                <div className={styles.rectangleParent}>
                  <img
                    className={styles.frameChild}
                    alt=""
                    src="/rectangle-1@2x.png"
                  />
                  {/* <img
                    className={styles.novaLogo2}
                    loading="lazy"
                    alt=""
                    src="/nova-logo-2@2x.png"
                  /> */}
                </div>
                <div className={styles.image14Wrapper}>
                  <ReactPlayer
                    url={Hino}
                    controls
                    playing
                    width="700px"
                    height="50px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default pagehino;
