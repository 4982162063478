import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import TopAreFooter from "./TopAreaFooter"
import BottomAreaFooter from "./BottomAreaFooter";
import styles from "./Footer.module.css";
import EndAreaFooter from "./EndAreaFooter";

const Footer = () => {
  const navigate = useNavigate();

  const onItemContainerClick = useCallback(() => {
    navigate("/propostapedagogica");
  }, [navigate]);

  return (
    <>
      <footer className={styles.footer}>
        <div className={styles.linkSvg} />
        <TopAreFooter onItemContainerClick={onItemContainerClick} />
        <div className={styles.divfooterLine} />
        <BottomAreaFooter />
        <EndAreaFooter />
      </footer>
    </>
  );
};

export default Footer;
