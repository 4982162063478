import React from 'react';
import styles from './AcceptCookies.module.css';
import img from '../../ASSETS/icons/cookie.png'
const AcceptCookies = ({ onAccept }) => {
    return (
        <div className={styles.acceptCookies}>
            <img src={img}></img>
            <p>Ao navegar nesse site você concorda com nossa <strong>política de privacidade.</strong></p>
            <button onClick={onAccept}>OK</button>
        </div>
    );
};

export default AcceptCookies;
