import { useMemo } from "react";
import styles from "./TopAreaFooter.module.css";
import { Link } from "react-router-dom";

const TopAreaFooter = ({
  propPadding,
  onNOVALOGO11Click,
  onItemContainerClick,
}) => {
  const frameDivStyle = useMemo(() => {
    return {
      padding: propPadding,
    };
  }, [propPadding]);

  const items = [
    {
      id: "2",
      src: "/esporte-1-1.svg",
      label: "Nossos Esportes",
      link:"/esportes"
    },
    {
      id: "3",
      src: "/vector-4.svg",
      label: "Alimentação saudável",
      link:"/alimentacaosaudavel"
    },
    {
      id: "4",
      src: "/mascarasdeteatro-1-1.svg",
      label: "Projeto Teatro",
      link:"/teatro"
    },
    {
      id: "5",
      src: "/link--svg.svg",
      label: "Proposta pedagógica",
      link:"/propostapedagogica"
    },
    {
      id: "6",
      src: "/maladeviagem-1.svg",
      label: "Trabalhe Conosco",
      link:"/trabalheconosco"
    },
  ];

  return (
    <div className={styles.databaseConnectionWrapper} style={frameDivStyle}>
      <div className={styles.logoFooterArea}>
        <img
          alt=""
          src='/nova-logo-1-2@2x.png'
          onClick={onNOVALOGO11Click}
        />
      </div>
      <div className={styles.databaseConnection}>
        {items.map((item) => (
          <Link to={item.link}>
            <div key={item.id} className="databaseConnectionWrapper">
              <div className={styles.databaseConnectionelement}>
                <img
                  className={styles[item.id]}
                  alt=""
                  src={item.src}
                  onClick={item.onClick}
                />
                <div className={styles.outputProcessor}>
                  <div className={styles.linkRoteiros}>{item.label}</div>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default TopAreaFooter;
