import React, { useState } from 'react';
import Header from '../components/Header/Header';
import styles from './Ensino.module.css';
import Footer from '../components/Footer/Footer';

import img1 from '../../src/ASSETS/Ensino/infantill.4e003e3dce9616d24c4d.png';
import img2 from '../../src/ASSETS/Ensino/fundamental.d9e7e3479afd096ee2ed.jpg';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Ensino = () => {
    const items = [
        {
            id: 1,
            img: img1,
            title: "Ensino Infantil",
            text: "A proposta pedagógica da educação infantil no Colégio Alternativo Colaço é fundamentada na Base Nacional Comum Curricular (BNCC), que estabelece diretrizes essenciais para o desenvolvimento integral das crianças. Priorizando uma abordagem centrada no aluno, o colégio busca promover experiências educativas significativas, que estimulem o desenvolvimento cognitivo, socioemocional e motor das crianças. Através de práticas pedagógicas inovadoras, o Colégio Alternativo Colaço visa proporcionar um ambiente acolhedor e estimulante, onde as crianças sintam-se seguras para explorar, criar e aprender, desenvolvendo assim habilidades essenciais para sua formação plena como cidadãos críticos e participativos na sociedade."
        },
        {
            id: 2,
            img: img2,
            title: "Ensino Fundamental I",
            text: "A proposta pedagógica do ensino fundamental no Colégio Alternativo Colaço é alinhada com os princípios e diretrizes estabelecidos pela Base Nacional Comum Curricular (BNCC), que norteiam a educação básica no país. Priorizando uma abordagem interdisciplinar e contextualizada, o colégio busca promover um ensino que valorize não apenas o desenvolvimento acadêmico, mas também as habilidades socioemocionais e o pensamento crítico dos alunos. Através de projetos pedagógicos integrados e práticas educativas inovadoras, o Colégio Alternativo Colaço visa formar estudantes autônomos, criativos e comprometidos com valores éticos e sociais, preparando-os para os desafios do mundo contemporâneo e para uma participação ativa na construção de uma sociedade mais justa e democrática."
        }
    ];

    const [selectedItem, setSelectedItem] = useState(items[0]);

    const handleTitleClick = (item) => {
        setSelectedItem(item);
    };

    return (
        <div className={styles.macbookAir1}>
            <Header />
            <section className={styles.all}>
                <h1>Nosso ensino</h1>
                <div className={styles.content}>
                    <div className={styles.titles}>
                        {items.map((item) => (
                            <div
                                className={styles.title}
                                key={item.id}
                                onClick={() => handleTitleClick(item)}
                                style={{
                                    cursor: 'pointer',
                                    fontWeight: selectedItem && selectedItem.id === item.id ? 'bold' : 'normal'
                                }}
                            >
                                {selectedItem && selectedItem.id === item.id ?
                                    <div className={styles.colorBall}></div>
                                    : <div style={{ width: "5.2px" }}></div>
                                }
                                <p>{item.title}</p>
                            </div>
                        ))}
                    </div>
                    {selectedItem && (
                        <div className={styles.selectedContent}>
                            <div className={styles.text}>
                                <h2>{selectedItem.title}</h2>
                                <p>{selectedItem.text}</p>
                            </div>
                            <div className={styles.img}>
                                <img src={selectedItem.img} alt={selectedItem.title} />
                            </div>
                        </div>
                    )}
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default Ensino;
