import React, { useState } from 'react';
import styles from './ModalProjetos.module.css';
import { Link, useNavigate } from 'react-router-dom';
import { HiX } from 'react-icons/hi';
import { FaArrowLeftLong } from "react-icons/fa6";

const ModalProjetos = ({ onClose, closeProjetos }) => {
    const [fadeOut, setFadeOut] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const navigate = useNavigate();

    const handleOutsideClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
            triggerCloseAnimation();
        }
    };

    const triggerCloseAnimation = () => {
        setFadeOut(true);
        setTimeout(() => {
            onClose();
        }, 500);
    };

    const menuItems = [
        { label: "Projeto Eu Sou o Escritor", onClick: () => navigate("/projetoeusouoescritor") },
        { label: "Projeto Teatro Encantado", onClick: () => navigate("/teatro") },
        { label: "Projeto Alimentação Saudável", onClick: () => navigate("/alimentacaosaudavel") },
        { label: "Projeto Cuidando Do Planeta", onClick: () => navigate("/projetocuidandodoplaneta") },
        { label: "Projeto Amigo Da Escola", onClick: () => navigate("/projetoamigodaescola") },
        { label: "Projeto Criando Arte", onClick: () => navigate("/projetocriandoarte") },
        { label: "Projeto Musicalização", onClick: () => navigate("/projetomusicalizacao") },
        { label: "Projeto Iniciação cientifica", onClick: () => navigate("/projetoiniciacaocientifica") },
        { label: "Projeto Emergência Médica", onClick: () => navigate("/projetoemergenciamedica") },
    ];

    return (
        <div className={`${styles.modalOverlay} ${fadeOut ? styles.fadeOut : ''}`} onClick={handleOutsideClick}>
            <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                <div className={styles.closeButton}>
                    <Link to="/" className={styles.menuLink}>
                        <img
                            className={styles.listItemLink}
                            loading="lazy"
                            alt=""
                            src="/list--item--link.svg"
                        />
                    </Link>
                    <div onClick={onClose}><HiX /></div>
                </div>
                <div className={styles.returnArrow} onClick={closeProjetos}><FaArrowLeftLong /> Voltar</div>
                {menuItems.map((item, index) => (
                    <div className={styles.menuHeaderOpenItem} key={index} onClick={item.onClick}>
                        {item.label}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ModalProjetos;
