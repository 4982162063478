import React from 'react'
import styles from './ProjetoAmigodaEscola.module.css'

import img1 from '../ASSETS/AmigoDaEscola/03da515d-fc9a-46e6-9dd7-3d733492eb1c.jpg'
import img3 from '../ASSETS/AmigoDaEscola/2a22a12e-a3ae-4c90-b13c-1e2ab5275965.jpg'
import img4 from '../ASSETS/AmigoDaEscola/384a028e-4667-401d-a4e3-c1b2e262f7b9.jpg'
import img5 from '../ASSETS/AmigoDaEscola/453cc152-a9c4-441f-b09a-7fc551995e2f.jpg'
import img6 from '../ASSETS/AmigoDaEscola/8dfd7417-e4c6-4b04-9fd5-3a88028a59c2.jpg'
import img7 from '../ASSETS/AmigoDaEscola/aa0b6bf3-9d88-4cef-9bd0-73547cec7062.jpg'
import img8 from '../ASSETS/AmigoDaEscola/crianca-dizer-ola-ao-amigo-e-ir-para-a-escola-juntos_97632-6514.avif'

import Header from '../components/Header/Header';
import Projetos from '../components/ProjetosGeniais/Projetos';
import Footer from '../components/Footer/Footer';

const ProjetoAmigodaEscola = () => {
    const projetos = [
        {
            id: 1,
            url: img1
        },
        {
            id: 2,
            url: img3
        },
        {
            id: 3,
            url: img4
        },
        {
            id: 4,
            url: img5
        },
        {
            id: 5,
            url: img6
        },
        {
            id: 6,
            url: img7
        },
    ];

    return (
        <div className={styles.macbookAir1}>
            <Header />
            <section className={styles.EsportesSection}>
                <div className={styles.EsportesFull}>
                    <h1>Projeto Amigo da Escola</h1>
                    <div className={styles.ConteudoFull}>
                        <div>
                            <text>
                                No Colégio Alternativo Colaço, acreditamos que a escola é um universo mágico onde sonhos ganham asas e a imaginação voa alto. É com esse espírito encantador que apresentamos o nosso maravilhoso *Projeto Amigo da Escola*!

                                Nesse projeto especial, a escola se transforma em um grande palco de aventuras e descobertas. Pais, familiares, ex-alunos e empresas parceiras têm a oportunidade de compartilhar suas histórias e conhecimentos com nossas crianças, criando um espetáculo de aprendizado e diversão.

                                Os pais e familiares são convidados a falar sobre suas profissões, mostrando como suas atividades diárias podem inspirar nossos pequenos exploradores. Quer sejam médicos que combatem dragões invisíveis, engenheiros que constroem pontes para o futuro ou artistas que pintam os sonhos mais coloridos, suas histórias são verdadeiros tesouros para nossas crianças.

                                Nossos ex-alunos, que já trilharam os corredores do Colégio Alternativo Colaço, também desempenham um papel fundamental. Com suas experiências e aventuras, eles trazem um baú cheio de memórias e lições valiosas. Suas jornadas são mapas que guiam os pequenos em suas próprias missões de vida.

                                As empresas parceiras, verdadeiros aliados nessa aventura, contribuem com seu conhecimento e inovação. Cada empresa é um reino encantado que enriquece nosso mundo escolar com suas valiosas contribuições.

                                Cada visita e palestra é uma nova história contada, uma nova janela aberta para o conhecimento. As crianças se encantam, se inspiram e aprendem que o mundo é cheio de possibilidades. Descobrem que podem ser o que quiserem – desde cientistas que exploram galáxias distantes até chefs que criam as mais deliciosas poções culinárias.

                                O Projeto Amigo da Escola transforma o Colégio Alternativo Colaço em um lugar onde o saber é uma aventura contínua, onde cada convidado é um herói que enriquece a vida escolar com sabedoria e experiência. Este projeto é uma jornada inesquecível que contribui para a formação de cidadãos conscientes, criativos e inovadores.

                                Venham conhecer e fazer parte dessa iniciativa encantadora! Juntos, vamos continuar a transformar a educação e a vida de nossas crianças com histórias inspiradoras e experiências enriquecedoras.
                            </text>
                        </div>
                        <div className={styles.imgProfessor}>
                            <div>
                                <img src={img8} />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <Projetos
                        estrutura="Nosso Projeto"
                        propWidth="949px"
                        propPadding="unset"
                        propWidth1="256px"
                        propPaddingRight="unset"
                        frameDivMinWidth="949px"
                        frameDivDebugCommit="unset"
                        projetos={projetos}
                    />
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default ProjetoAmigodaEscola