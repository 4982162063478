import React from 'react';
import styles from "./FloatButtom.module.css";
import imgFloatButton from "../../ASSETS/Podemos Ajudar.png";

const FloatButton = () => {
  const handleWhatsAppClick = () => {
    window.location.href = 'https://api.whatsapp.com/send?phone=5585988068633'; // Substitua SEUNUMERO pelo número de telefone do WhatsApp
  };

  return (
    <div className={styles.floatButtonContainer}>
      <a href="https://api.whatsapp.com/send?phone=SEUNUMERO" target="_blank" rel="noopener noreferrer" className={styles.floatButton} onClick={handleWhatsAppClick}>
        <img src={imgFloatButton} alt="Float Button" className={styles.floatButtonIcon} />
      </a>
    </div>
  );
}

export default FloatButton;
