import React from 'react'
import styles from './EndAreaFooter.module.css'
const EndAreaFooter = () => {
    return (
        <div className={styles.inputModifier}>
            <div className={styles.footer}>
                <div className={styles.todosOsDireitos}>
                    Todos os direitos reservados.
                </div>
                <div className={styles.linkPoltica}>
                    Política de Confidencialidade
                </div>
            </div>
        </div>
    )
}

export default EndAreaFooter