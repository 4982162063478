import React from 'react'
import styles from './ProjetoCriandoArte.module.css'

import img1 from '../ASSETS/CriandoArte/25f08353-a1cb-4fce-9885-3638b4d61fe2.jpg'
import img3 from '../ASSETS/CriandoArte/3411f0cd-9b6a-4545-8a0a-1719670cdd5f.jpg'
import img4 from '../ASSETS/CriandoArte/3fdabe30-7173-4057-ab45-22d1cd9bbd73.jpg'
import img5 from '../ASSETS/CriandoArte/4638b5c8-73dc-4a45-8936-dfad50a42dda.jpg'
import img6 from '../ASSETS/CriandoArte/5d701922-354b-4cac-a725-f9ee68403600.jpg'
import img7 from '../ASSETS/CriandoArte/610d717a-9e3d-471b-b20d-3d73f931296c.jpg'
import img8 from '../ASSETS/CriandoArte/7db64463-5496-446f-851a-464eb019a709.jpg'
import img9 from '../ASSETS/CriandoArte/92595116-c444-42c4-9946-059d6507387e.jpg'

import header from '../ASSETS/CriandoArte/header.jpg'

import Header from '../components/Header/Header';
import Projetos from '../components/ProjetosGeniais/Projetos';
import Footer from '../components/Footer/Footer';

const ProjetoCriandoArte = () => {
    const projetos = [
        {
            id: 1,
            url: img1
        },
        {
            id: 2,
            url: img3
        },
        {
            id: 3,
            url: img4
        },
        {
            id: 4,
            url: img5
        },
        {
            id: 5,
            url: img6
        },
        {
            id: 6,
            url: img7
        },
        {
            id: 7,
            url: img8
        },
        {
            id: 8,
            url: img9
        },
    ];

    return (
        <div className={styles.macbookAir1}>
            <Header />
            <section className={styles.EsportesSection}>
                <div className={styles.EsportesFull}>
                    <h1>Projeto Criando Arte</h1>
                    <div className={styles.ConteudoFull}>
                        <div>
                            <text>
                                No Colégio Alternativo Colaço, a escolha de produzir artes com materiais reciclados durante as aulas de artes para crianças tem uma importância multifacetada e profundamente significativa.

                                Primeiramente, ao utilizar materiais reciclados, o colégio promove a conscientização ambiental entre as crianças desde cedo. As crianças aprendem sobre a importância da sustentabilidade e da reutilização de materiais que, de outra forma, seriam descartados. Isso não apenas reduz o impacto ambiental da escola, mas também incute valores de responsabilidade ecológica nas crianças.

                                Além disso, a prática de criar arte com materiais reciclados estimula a criatividade e a inovação. As crianças são desafiadas a ver potencial em objetos comuns e transformá-los em obras de arte. Esse exercício de imaginação não só desenvolve habilidades artísticas, mas também promove o pensamento crítico e a capacidade de resolução de problemas.

                                A culminância desse processo é a exposição de artes, onde as criações das crianças são exibidas para os familiares. Esse evento não só celebra o trabalho e a criatividade das crianças, mas também fortalece a parceria escola x família. O apoio dos familiares aumenta a autoestima da criança, que se sente valorizada e reconhecida por seus esforços e talentos. Os familiares têm a oportunidade de ver o progresso e o talento de seus filhos, o que aumenta o envolvimento e o apoio ao desenvolvimento educacional e artístico.

                                Trabalhando baseados em autores renomados, o Colégio Alternativo Colaço garante que as aulas de arte sejam fundamentadas em teorias e práticas reconhecidas, oferecendo uma educação artística de qualidade. As obras de artistas que utilizam materiais reciclados servem de inspiração, mostrando que a arte pode ser bela e significativa, independentemente dos materiais usados.

                                Adotando essa abordagem, o Colégio Alternativo Colaço não só enriquece a educação artística das crianças, mas também contribui para a formação de cidadãos conscientes, criativos e inovadores.
                            </text>
                        </div>
                        <div className={styles.imgProfessor}>
                            <div>
                                <img src={header} />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <Projetos
                        estrutura="Nosso Projeto"
                        propWidth="949px"
                        propPadding="unset"
                        propWidth1="256px"
                        propPaddingRight="unset"
                        frameDivMinWidth="949px"
                        frameDivDebugCommit="unset"
                        projetos={projetos}
                    />
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default ProjetoCriandoArte