import { useMemo } from "react";
import styles from "./FrameComponent11.module.css";
import img from "../ASSETS/dd9260ec83de60205e13e9c20c70da1f.png"
const FrameComponent11 = ({
  frameDivFlex,
  frameDivWidth,
  frameDivPadding,
  frameDivWidth1,
  frameDivPaddingRight,
  propWidth,
  propAlignSelf,
  propWidth1,
  propHeight,
  propLeft,
  onNOVALOGO2Click,
}) => {
  const frameDiv1Style = useMemo(() => {
    return {
      flex: frameDivFlex,
      width: frameDivWidth,
      padding: frameDivPadding,
    };
  }, [frameDivFlex, frameDivWidth, frameDivPadding]);

  const nossaEstruturaStyle = useMemo(() => {
    return {
      width: frameDivWidth1,
      paddingRight: frameDivPaddingRight,
    };
  }, [frameDivWidth1, frameDivPaddingRight]);

  const frameDiv2Style = useMemo(() => {
    return {
      width: propWidth,
      alignSelf: propAlignSelf,
    };
  }, [propWidth, propAlignSelf]);

  const frameDiv3Style = useMemo(() => {
    return {
      width: propWidth1,
    };
  }, [propWidth1]);

  const image19IconStyle = useMemo(() => {
    return {
      height: propHeight,
      left: propLeft,
    };
  }, [propHeight, propLeft]);

  return (
    <div className={styles.nossaEstruturaParent} style={frameDiv1Style}>
      <h1 className={styles.nossaEstrutura} style={nossaEstruturaStyle}>
        <p className={styles.nossaEstrutura1}>Nossa Estrutura</p>
      </h1>
      <div className={styles.frameParent}>
        <div className={styles.aInstituioBsicaColgioAParent}>
          <div className={styles.aInstituioBsicaContainer}>
            <p className={styles.aInstituioBsica}>
              Imagine que a escola é como um grande parque de diversões para o aprendizado! Aqui no Colégio Alternativo Colaço, temos tudo planejado para que as crianças se divirtam enquanto aprendem e crescem.

              Nossa escola é como um mundo mágico, cheio de salas coloridas e cheias de vida, onde cada uma tem apenas 15 alunos para garantir que todos recebam atenção especial. E não para por aí! Temos aulas de recreação aquática,, de música, de inglês e até de tecnologia, para explorar o mundo moderno de forma divertida.
            </p>
            <p className={styles.oInvestimentoNa}>
              E quando bate a fome, nosso refeitório está sempre pronto para servir refeições deliciosas e saudáveis. Não podemos esquecer da nossa piscina, biblioteca encantada, quarto do sono para relaxar, e um parquinho só para os pequenos se divertirem ao ar livre. Ah, e tem até uma brinquedoteca e uma sala de teatro para soltar a imaginação!

              E claro, tudo isso é cuidado com muito carinho e segurança, com 40 câmeras de monitoramento e serviços de emergência médica disponíveis a qualquer momento. Aqui, aprender é uma aventura que nunca acaba!
            </p>
          </div>
          <div className={styles.diferenciaisSalasLimitadaContainer}>
            <p className={styles.diferenciais}>Diferenciais: </p>
            <ul className={styles.salasLimitadasA15AlunosA}>
              <li className={styles.salasLimitadasA}>
                Salas limitadas a 15 alunos;
              </li>
              <li className={styles.aulaDeRecreao}>
                Aula de recreação aquática;
              </li>
              <li className={styles.aulaDeMsica}>Aula de música;</li>
              <li className={styles.aulaDeIngls}>Aula de inglês;</li>
              <li className={styles.aulaDeInformtica}>Aula de Informática; </li>
              <li className={styles.servioNutricional}>Serviço Nutricional;</li>
              <li className={styles.monitoramentoCom32}>
                Monitoramento com 32 câmeras de segurança;
              </li>
              <li className={styles.serviosDeEmergncias}>
                Serviços de emergências médicas;
              </li>
              <li className={styles.projetosPedaggicosDa}>
                Projetos pedagógicos da escola;
              </li>
              <li className={styles.piscina}>Piscina;</li>
              <li className={styles.biblioteca}>Biblioteca;</li>
              <li className={styles.quartoDoSono}>Quarto do Sono; </li>
              <li className={styles.parqueInfantil}>Parque Infantil; </li>
              <li>Refeitório.</li>
            </ul>
          </div>
        </div>
        <div className={styles.frameWrapper} style={frameDiv2Style}>
          <div className={styles.image19Parent} style={frameDiv3Style}>
            <img
              className={styles.image19Icon}
              alt=""
              src={img}
              style={image19IconStyle}
            />
            {/* <img
              className={styles.novaLogo2}
              alt=""
              src="/nova-logo-2@2x.png"
              onClick={onNOVALOGO2Click}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrameComponent11;
