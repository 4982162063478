import React from 'react'
import styles from './ProjetoEmergenciaMedica.module.css'

import img1 from '../ASSETS/Emergencias Medicas/0b85a3a3-df08-42fd-8858-7a85fab51ee9.jpg'
import img2 from '../ASSETS/Emergencias Medicas/1d6c187c-9586-4734-a8b2-9aa20a81515d.jpg'
import img3 from '../ASSETS/Emergencias Medicas/2811c8bf-46ec-44d8-aeba-4b3e4c358b45.jpg'
import img4 from '../ASSETS/Emergencias Medicas/3526dc0e-ec5e-4eb1-bfc4-69375e4b74ff.jpg'
import img5 from '../ASSETS/Emergencias Medicas/5ddb0149-2dc9-442e-aec1-e67210367c98.jpg'
import img6 from '../ASSETS/Emergencias Medicas/996dffd8-02f0-4a85-92c4-dc61988e91e1.jpg'
import img7 from '../ASSETS/Emergencias Medicas/9eb2f310-183e-43f6-ace8-7b2fb437dfb1.jpg'
import img8 from '../ASSETS/Emergencias Medicas/ec027413-8988-4615-b1d3-c578d483f1ff.jpg'
import img9 from '../ASSETS/Emergencias Medicas/f781eb63-6b2c-495f-8d4b-8d86153871ea.jpg'
import img10 from '../ASSETS/Emergencias Medicas/ff764f4b-55f7-4b74-a9da-99ebd9100c86.jpg'



import Projetos from '../components/ProjetosGeniais/Projetos'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'

const ProjetoEmergenciaMedica = () => {
    const projetos = [
        {
            id: 1,
            url: img1
        },
        {
            id: 2,
            url: img2
        },
        {
            id: 4,
            url: img4
        },
        {
            id: 5,
            url: img5
        },
        {
            id: 6,
            url: img6
        },
        {
            id: 7,
            url: img7
        },
        {
            id: 8,
            url: img8
        },
        {
            id: 9,
            url: img9
        },
        {
            id: 10,
            url: img10
        },
    ];

    return (
        <div className={styles.macbookAir1}>
            <Header />
            <section className={styles.EsportesSection}>
                <div className={styles.EsportesFull}>
                    <h1>Projeto Emergências Médicas</h1>
                    <div className={styles.ConteudoFull}>
                        <div>
                            <text>
                                Ter um serviço de emergência médica da empresa prestadora SAT Serviços de Emergências Médicas no Colégio Alternativo Colaço é fundamental para garantir a segurança e o bem-estar dos alunos, professores e funcionários. Crianças estão sujeitas a acidentes, quedas, reações alérgicas e outros problemas de saúde que podem ocorrer a qualquer momento. Com o apoio especializado da SAT, é possível oferecer atendimento imediato em situações de urgência, minimizando riscos e proporcionando uma resposta rápida e eficaz. Além disso, a SAT realiza anualmente um treinamento de primeiros socorros para todos os funcionários do colégio, garantindo que toda a equipe esteja preparada para agir em situações de emergência. Essa iniciativa traz ainda mais tranquilidade aos pais, fortalece a confiança na instituição e demonstra o compromisso do Colégio Alternativo Colaço com a saúde e segurança de todos.
                            </text>
                        </div>
                        <div className={styles.imgProfessor}>
                            <div>
                                <img src={img3} />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <Projetos
                        estrutura="Nosso Projeto"
                        propWidth="949px"
                        propPadding="unset"
                        propWidth1="256px"
                        propPaddingRight="unset"
                        frameDivMinWidth="949px"
                        frameDivDebugCommit="unset"
                        projetos={projetos}
                    />
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default ProjetoEmergenciaMedica