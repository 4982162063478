import React from 'react'
import styles from './ProjetoIniciacaoCientifica.module.css'
import Projetos from '../components/ProjetosGeniais/Projetos';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';

import img1 from '../ASSETS/IniciacaoCientifica/071f3128-7320-49ee-a5a8-55a88a746695.jpg';
import img3 from '../ASSETS/IniciacaoCientifica/21cb79df-08ff-4261-be20-e55c457b8ef7.jpg';
import img4 from '../ASSETS/IniciacaoCientifica/44564868-1e7f-466d-823e-17a7657daff4.jpg';
import img5 from '../ASSETS/IniciacaoCientifica/5a76ea79-d433-4fa4-b083-1335856278e3.jpg';
import img7 from '../ASSETS/IniciacaoCientifica/5c358655-9864-46c7-9f22-8082eec9b1fb.jpg';
import img6 from '../ASSETS/IniciacaoCientifica/83cb2c68-d584-42ff-85c2-d40ba0969e4b.jpg';
import img8 from '../ASSETS/IniciacaoCientifica/8778a14f-1229-4c89-8802-22cf9f41f6c8.jpg';
import img9 from '../ASSETS/IniciacaoCientifica/92ad7f12-9692-4d60-b246-6f32fa238136.jpg';
import img10 from '../ASSETS/IniciacaoCientifica/ea517bf7-f993-4f9b-b67d-34f9b9b96736.jpg';
import img11 from '../ASSETS/IniciacaoCientifica/b81240b1-1ac7-4353-8d4d-d14648ded3e9.jpg';
import img12 from '../ASSETS/IniciacaoCientifica/cb4bd62d-7419-49b4-bb7c-bce599faa7a2.jpg';

import header from '../ASSETS/IniciacaoCientifica/Jovens-cientistas.-Ilustra-pch.vector-freepik.jpg'

const ProjetoIniciacaoCientifica = () => {
    const projetos = [
        {
            id: 1,
            url: img1
        },
        {
            id: 2,
            url: img3
        },
        {
            id: 3,
            url: img4
        },
        {
            id: 4,
            url: img5
        },
        {
            id: 5,
            url: img6
        },
        {
            id: 6,
            url: img8
        },
        {
            id: 7,
            url: img9
        },
        {
            id: 8,
            url: img10
        },
        {
            id: 9,
            url: img11
        },
        {
            id: 10,
            url: img12
        },
    ];

    return (
        <div className={styles.macbookAir1}>
            <Header />
            <section className={styles.EsportesSection}>
                <div className={styles.EsportesFull}>
                    <h1>Projeto Iniciação Científica</h1>
                    <div className={styles.ConteudoFull}>
                        <div>
                            <text>
                                Tem o objetivo de promover o desenvolvimento do pensamento crítico, a curiosidade científica e as habilidades de investigação nas crianças, incentivando a exploração do mundo ao seu redor por meio de métodos científicos básicos e atividades práticas.
                                O projeto visa, assim, não apenas introduzir as crianças ao mundo da ciência, mas também cultivar habilidades essenciais como a curiosidade, a investigação e a comunicação, que são fundamentais para o desenvolvimento cognitivo e social dos alunos.
                            </text>
                        </div>
                        <div className={styles.imgProfessor}>
                            <div>
                                <img src={header} />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <Projetos
                        estrutura="Nosso Projeto"
                        propWidth="949px"
                        propPadding="unset"
                        propWidth1="256px"
                        propPaddingRight="unset"
                        frameDivMinWidth="949px"
                        frameDivDebugCommit="unset"
                        projetos={projetos}
                    />
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default ProjetoIniciacaoCientifica