import React from 'react'
import styles from './ProjetoEuSouOEscritor.module.css'
import teatroimage from '../ASSETS/EuSouOEscritor/374aa64c-ca4c-408b-9b87-06c6f0119853.jpg'
import img1 from '../ASSETS/EuSouOEscritor/374aa64c-ca4c-408b-9b87-06c6f0119853.jpg'
import img3 from '../ASSETS/EuSouOEscritor/5579edea-7b66-4ea7-84c2-56cba1bf6a69.jpg'
import img4 from '../ASSETS/EuSouOEscritor/da15171b-4ac7-4a27-ada7-f942419b84c2.jpg'
import img5 from '../ASSETS/EuSouOEscritor/dfc868cd-4593-4256-9f45-79f4f7f79422.jpg'
import img6 from '../ASSETS/EuSouOEscritor/374aa64c-ca4c-408b-9b87-06c6f0119853.jpg'
import img7 from '../ASSETS/EuSouOEscritor/374aa64c-ca4c-408b-9b87-06c6f0119853.jpg'

import Header from '../components/Header/Header';
import Projetos from '../components/ProjetosGeniais/Projetos';
import Footer from '../components/Footer/Footer';

const ProjetoEuSouOEscritor = () => {
    const projetos = [
        {
            id: 1,
            url: img1
        },
        {
            id: 2,
            url: img3
        },
        {
            id: 3,
            url: img4
        },
        {
            id: 4,
            url: img5
        },
        {
            id: 5,
            url: img6
        },
        {
            id: 6,
            url: img7
        },
    ];

    return (
        <div className={styles.macbookAir1}>
            <Header />
            <section className={styles.EsportesSection}>
                <div className={styles.EsportesFull}>
                    <h1>Projeto eu Sou o Escritor</h1>
                    <div className={styles.ConteudoFull}>
                        <div>
                            <text>
                                O Mundo Infantil é marcado pela magia. Pensando nisso, o Colégio Alternativo Colaço desenvolveu esse projeto com o objetivo de incentivar bons hábitos nas crianças, mostrando a importância de valorizar o despertar da criatividade, por meio das habilidades de leitura, escrita e desenho, tendo como meta o desenvolvimento intelectual.
                                A criança gosta de ser notada e de receber atenção e elogios, e isso fortalece sua autoestima, preparando-a melhor para o mundo, assim, seu rendimento escolar e seu convívio familiar serão mais satisfatórios.
                                Nesse viés, é por meio desse projeto que a criança se comunica, expressa seus pensamentos e sentimentos que a rodeiam, pois, afinal escrever um livro para ela significa mergulhar num mundo onde tudo é possível. A fantasia é o limite!
                            </text>
                        </div>
                        <div className={styles.imgProfessor}>
                            <div>
                                <img src={img3} />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <Projetos
                        estrutura="Nosso Projeto"
                        propWidth="949px"
                        propPadding="unset"
                        propWidth1="256px"
                        propPaddingRight="unset"
                        frameDivMinWidth="949px"
                        frameDivDebugCommit="unset"
                        projetos={projetos}
                    />
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default ProjetoEuSouOEscritor