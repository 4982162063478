import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import styles from './PageEsportes.module.css';
import img1 from "../ASSETS/Esportes/Ballet.png";
import img2 from "../ASSETS/Esportes/Karatê.png";
import img3 from "../ASSETS/Esportes/Natação.png";
import { Link } from 'react-router-dom';


const PageEsportes = () => {
    const imagens = [
        {
            url: img1,
            descricao: "Bullet",
            link: "/esportes/ballet"
        },
        {
            url: img2,
            descricao: "Karate",
            link: "/esportes/karate"
        },
        {
            url: img3,
            descricao: "Natação",
            link: "/esportes/natacao"
        }
    ];

    // Função para criar um elemento de imagem e descrição
    const criarElemento = (imagem) => (
        <Link to={imagem.link}>
            <div key={imagem.url} className={styles.EsporteItem}>
                <img src={imagem.url} alt={imagem.descricao} />
            </div>
        </Link>
    );

    return (
        <div className={styles.macbookAir1}>
            <Header />
            <section className={styles.EsportesSection}>
                <div className={styles.EsportesFull}>
                    <h1># Nossos Esportes</h1>
                    <hr />
                    <div className={styles.ListEsportes}>
                        {imagens.map(criarElemento)}
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default PageEsportes;
