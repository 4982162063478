import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import FrameComponent7 from "../components/FrameComponent7";
import FlowControl from "../components/FlowControl";
import OutputFormatter from "../components/OutputFormatter";
import styles from "./MacBookAir2.module.css";
import Header from "../components/Header/Header";

const MacBookAir2 = () => {
  const navigate = useNavigate();

  const onNOVALOGO1Click = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onListItemLinkClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onListItem5Click = useCallback(() => {
    navigate("/macbook-air-5");
  }, [navigate]);

  const onListItem7Click = useCallback(() => {
    navigate("/macbook-air-2");
  }, [navigate]);

  const onListItem10Click = useCallback(() => {
    navigate("/macbook-air-5");
  }, [navigate]);

  const onListItem12Click = useCallback(() => {
    navigate("/macbook-air-2");
  }, [navigate]);

  const onListItem13Click = useCallback(() => {
    // Please sync "MacBook Air - 6" to the project
  }, []);

  const onListItem14Click = useCallback(() => {
    navigate("/macbook-air-3");
  }, [navigate]);

  const onListItemClick = useCallback(() => {
    navigate("/macbook-air-4");
  }, [navigate]);

  const onVectorIconClick = useCallback(() => {
    // Please sync "MacBook Air - 7" to the project
  }, []);

  const onListItem1Click = useCallback(() => {
    // Please sync "MacBook Air - 7" to the project
  }, []);

  return (
    <div className={styles.macbookAir3}>
      <div className={styles.image13} />
      <div className={styles.image11} />
      <section className={styles.divheaderBarParent}>
        <Header/>
        <FrameComponent7 />
      </section>
      <FlowControl />
      <OutputFormatter />
    </div>
  );
};

export default MacBookAir2;
