import React, { useMemo, useState, useEffect } from "react";
import styles from "./HeaderTop.module.css";
import { Link } from "react-router-dom";

const HeaderTop = ({
  frameHeaderPadding,
  listItemLinkSVGDebugCommit,
  listItemDebugCommit,
  listItemPaddingRight,
  onListItemClick,
  onConstantsIconClick,
  onListItem1Click,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const frameHeaderStyle = useMemo(() => {
    return {
      padding: frameHeaderPadding,
    };
  }, [frameHeaderPadding]);

  const listItemLinkSVGStyle = useMemo(() => {
    return {
      debugCommit: listItemLinkSVGDebugCommit,
    };
  }, [listItemLinkSVGDebugCommit]);

  const listItemStyle = useMemo(() => {
    return {
      debugCommit: listItemDebugCommit,
      paddingRight: listItemPaddingRight,
    };
  }, [listItemDebugCommit, listItemPaddingRight]);

  const mapItems = [
    {
      id: 1,
      src: '/list--item--link--svg.svg',
      name: 'Proposta Pedagógica',
      link: '/propostapedagogica'
    },
    {
      id: 3,
      src: '/mascarasdeteatro-1.svg',
      name: 'Teatro Encantado',
      link: '/teatro'
    },
    {
      id: 2,
      src: '/vector-2.svg',
      name: 'Alimentação Saudável',
      link: '/alimentacaosaudavel'
    },
    {
      id: 4,
      src: '/esporte-1.svg',
      name: 'Esportes',
      link:'/esportes'
    }
  ];

  const socialMediaItems = [
    {
      id: 1,
      src: '/link--faicon--img.svg',
      Link: 'https://www.instagram.com/colegioalternativocolaco'
    },
    {
      id: 2,
      src: '/tree-structure.svg',
      Link: 'https://www.facebook.com/colegioalternativocolaco'
    },
    {
      id: 3,
      src: '/link--faicon--img-1.svg',
      Link: 'https://www.linkedin.com/company/colegioalternativocolaco/mycompany/'
    },
    {
      id: 4,
      src: '/vector-1.svg',
      Link: 'https://www.youtube.com/@colegioalternativocolaco/featured'
    }
  ];

  return (
    <header className={styles.divheaderBarWrapper} style={frameHeaderStyle}>
      <div className={styles.divheaderBar}>
        <div className={styles.contentDivheaderBar}>
          <div className={styles.arrays}>
            {mapItems.map((item) => (
              <Link style={{ textDecoration: "none" }} to={item.link}>
                <div className={styles.flowControl}>
                  <img
                    className={styles.listItemLinkSvg}
                    loading="lazy"
                    alt=""
                    src={item.src}
                    style={listItemLinkSVGStyle}
                  />  
                  <div className={styles.libraries}>
                    <div
                      className={styles.listItem}
                      onClick={item.onClick}
                      style={listItemStyle}
                    >
                      {item.name}
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
          {windowWidth > 850 && (
            <div className={styles.inputFilter}>
              {socialMediaItems.map((item) => (
                <Link to={item.Link} key={item.id}>
                  <img
                    className={styles.linkFaIconImg}
                    alt=""
                    src={item.src}
                  />
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default HeaderTop;
