import React from 'react'
import HeaderTop from './HeaderTop'
import HeaderBottom from './HeaderBottom'

const Header = () => {
  return (
    <>
        <HeaderTop/>
        <HeaderBottom/>
    </>
  )
}

export default Header