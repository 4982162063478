import React from 'react'
import styles from './ProjetoMusicalização.module.css'
import Projetos from '../components/ProjetosGeniais/Projetos';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';

import img1 from '../ASSETS/Musicalização/102bc058-a3e9-4e1f-9b02-772ef9bf848f.jpg';
import img3 from '../ASSETS/Musicalização/14e6f4b4-0517-47ec-96db-a530109ef800.jpg';
import img4 from '../ASSETS/Musicalização/27c2e16a-d681-48ec-945e-4b0f93f9883f.jpg';
import img5 from '../ASSETS/Musicalização/7f54dc84-765f-49b6-824b-f3a8e61e6c05.jpg';
import img7 from '../ASSETS/Musicalização/d4e9eee3-e1b1-45bb-b05b-35d52165290a.jpg';
import img6 from '../ASSETS/Musicalização/e55ab6a9-b935-41ba-9f61-a1a7fe71dee5.jpg';


import header from '../ASSETS/Musicalização/header.png'

const ProjetoMusicalização = () => {
    const projetos = [
        {
            id: 1,
            url: img1
        },
        {
            id: 2,
            url: img3
        },
        {
            id: 3,
            url: img4
        },
        {
            id: 4,
            url: img5
        },
        {
            id: 6,
            url: img6
        },
        {
            id: 7,
            url: img7
        },
    ];

    return (
        <div className={styles.macbookAir1}>
            <Header />
            <section className={styles.EsportesSection}>
                <div className={styles.EsportesFull}>
                    <h1>Projeto Musicalização</h1>
                    <div className={styles.ConteudoFull}>
                        <div>
                            <text>
                                As aulas de musicalização na Educação Infantil são projetadas para despertar o interesse e a sensibilidade musical nas crianças desde cedo. Utilizando uma abordagem lúdica e interativa, nossos pequenos alunos são introduzidos ao mundo dos sons e ritmos de forma divertida e envolvente. Com atividades que incluem canto, dança, brincadeiras musicais e o uso de instrumentos simples, as crianças desenvolvem habilidades motoras, cognitivas e sociais, enquanto exploram sua criatividade e imaginação. A musicalização nessa fase é fundamental para o desenvolvimento integral, promovendo não só o gosto pela música, mas também habilidades essenciais como a concentração, a coordenação motora e o trabalho em grupo.

                                Já a musicalização no Ensino Fundamental I aprofunda o conhecimento e as habilidades dos alunos. As atividades são estruturadas para desenvolver a percepção auditiva, o senso rítmico e a capacidade de expressão musical.  As aulas também promovem o desenvolvimento emocional e social, estimulando a autoconfiança, a disciplina e o trabalho em equipe.

                                Acreditamos que  a música é uma poderosa ferramenta educativa, capaz de transformar a aprendizagem e o desenvolvimento dos alunos. Nossas aulas de musicalização são planejadas para proporcionar uma educação musical completa e significativa. Venha conhecer nosso trabalho e descubra como a música pode enriquecer a vida do seu filho.
                            </text>
                        </div>
                        <div className={styles.imgProfessor}>
                            <div>
                                <img src={header} />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <Projetos
                        estrutura="Nosso Projeto"
                        propWidth="949px"
                        propPadding="unset"
                        propWidth1="256px"
                        propPaddingRight="unset"
                        frameDivMinWidth="949px"
                        frameDivDebugCommit="unset"
                        projetos={projetos}
                    />
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default ProjetoMusicalização