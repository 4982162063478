import React from 'react'
import styles from './ProjetoCuidandoDoPlaneta.module.css'

import img1 from '../ASSETS/CuidandoDoPlaneta/39050409-6057-4a9a-bfd8-5fbb0dbb3457.jpg'
import img2 from '../ASSETS/CuidandoDoPlaneta/7d418a58-e257-419f-8af4-af0868322ee4.jpg'
import img3 from '../ASSETS/CuidandoDoPlaneta/ecologia-planeta-terra-para-cuidar-do-meio-ambiente_24640-20159.avif'
import img4 from '../ASSETS/EuSouOEscritor/da15171b-4ac7-4a27-ada7-f942419b84c2.jpg'
import img5 from '../ASSETS/EuSouOEscritor/dfc868cd-4593-4256-9f45-79f4f7f79422.jpg'
import img6 from '../ASSETS/EuSouOEscritor/374aa64c-ca4c-408b-9b87-06c6f0119853.jpg'
import img7 from '../ASSETS/EuSouOEscritor/374aa64c-ca4c-408b-9b87-06c6f0119853.jpg'

import Header from '../components/Header/Header';
import Projetos from '../components/ProjetosGeniais/Projetos';
import Footer from '../components/Footer/Footer';

const ProjetoCuidandoDoPlaneta = () => {
    const projetos = [
        {
            id: 1,
            url: img1
        },
        {
            id: 2,
            url: img2
        },
    ];

    return (
        <div className={styles.macbookAir1}>
            <Header />
            <section className={styles.EsportesSection}>
                <div className={styles.EsportesFull}>
                    <h1>Projeto Cuidando do Planeta</h1>
                    <div className={styles.ConteudoFull}>
                        <div>
                            <text>
                                No Colégio Alternativo Colaço, acreditamos que nosso compromisso é formar cidadãos conscientes e engajados com a sustentabilidade e o cuidado com o meio ambiente. É com grande orgulho que apresentamos o projeto "Cuidando do Planeta", uma iniciativa que visa promover práticas ecológicas entre nossos alunos e a comunidade.

                                Objetivos do Projeto

                                O projeto "Cuidando do Planeta" tem como principais objetivos:

                                1. Educação Ambiental: Integramos a consciência ambiental ao currículo escolar, proporcionando conhecimento sobre a importância da preservação dos recursos naturais.

                                2. Práticas Sustentáveis: Incentivamos ações práticas que contribuem para a sustentabilidade, como reciclagem, economia de água e energia, e redução de resíduos.

                                3. Engajamento Comunitário: Envolvemos alunos, pais, professores e a comunidade em geral em atividades que promovam o cuidado com o meio ambiente.

                                Atividades Desenvolvidas

                                O projeto "Cuidando do Planeta" engloba diversas atividades e programas, entre eles:

                                - Oficinas de Reciclagem: Aulas práticas sobre como separar e reciclar corretamente os materiais.

                                - Caminhadas Ecológicas: Passeios educativos para conhecer a fauna e flora locais e entender a importância de preservar os ecossistemas.

                                - Campanhas de Conscientização: Palestras e campanhas voltadas para a economia de água e energia, além de práticas para reduzir o desperdício.

                                Resultados e Impactos

                                Temos observado um impacto positivo tanto no ambiente escolar quanto na comunidade. Nossos alunos estão mais conscientes sobre suas ações e seu papel na preservação do planeta. Além disso, conseguimos reduzir significativamente o consumo de papel e plástico na escola, promovendo um ambiente mais sustentável e saudável.

                                Como Participar

                                Convidamos todos a se envolverem no projeto "Cuidando do Planeta". Juntos, podemos fazer a diferença e garantir um futuro melhor para as próximas gerações. Pais e responsáveis podem participar das oficinas e palestras, enquanto os alunos são incentivados a aplicar em casa o que aprendem na escola.

                                Para mais informações sobre o projeto e como participar, entre em contato com a secretaria do Colégio Alternativo Colaço. Juntos, vamos cuidar do nosso planeta!
                            </text>
                        </div>
                        <div className={styles.imgProfessor}>
                            <div>
                                <img src={img3} />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <Projetos
                        estrutura="Nosso Projeto"
                        propWidth="949px"
                        propPadding="unset"
                        propWidth1="256px"
                        propPaddingRight="unset"
                        frameDivMinWidth="949px"
                        frameDivDebugCommit="unset"
                        projetos={projetos}
                    />
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default ProjetoCuidandoDoPlaneta