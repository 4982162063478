import React from 'react'
import styles from './ProjetoAlimentacaoSaldavel.module.css'
import Projetos from '../components/ProjetosGeniais/Projetos';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import alimentacaoSaudavelimage from '../ASSETS/AlimentacaoSaudavel/OIP.jpg';
import img2 from '../ASSETS/AlimentacaoSaudavel/467cb24a-6e0b-42f0-af78-0ab0af4dd22f.jpg';
import img3 from '../ASSETS/AlimentacaoSaudavel/51d1a066-c58e-4ea0-8491-731d9c3fae1f.jpg';
import img4 from '../ASSETS/AlimentacaoSaudavel/7cdda7d1-2811-456b-88a3-829ae7227246.jpg';
import img5 from '../ASSETS/AlimentacaoSaudavel//c5907d79-70af-47cd-a513-d4a58f355f75.jpg';
import img7 from '../ASSETS/AlimentacaoSaudavel/d7a720ad-d739-49ea-9ff2-2e6817cff52d.jpg';

const ProjetoAlimentacaoSaldavel = () => {
    const projetos = [
        {
            id: 1,
            url: img2
        },
        {
            id: 2,
            url: img3
        },
        {
            id: 3,
            url: img4
        },
        {
            id: 4,
            url: img5
        },
        {
            id: 6,
            url: img7
        },
    ];

    return (
        <div className={styles.macbookAir1}>
            <Header />
            <section className={styles.EsportesSection}>
                <div className={styles.EsportesFull}>
                    <h1>Projeto Alimentação Saudável</h1>
                    <div className={styles.ConteudoFull}>
                        <div>
                            <text>
                                No Colégio Alternativo Colaço, sabemos que uma alimentação saudável é muito importante para que as crianças cresçam bem, tanto fisicamente quanto intelectualmente. Por isso, na nossa escola, oferecemos várias refeições, como lanche, almoço e jantar. Nossas comidas são preparadas com cuidado, seguindo a orientação de uma nutricionista especializada em Nutrição, para garantir que sejam equilibradas, saudáveis e atendam às necessidades das crianças.

                                Trabalhamos em parceria com as famílias para construir hábitos alimentares saudáveis.

                                Desenvolvemos projetos especiais para incentivar as crianças a experimentarem alimentos saudáveis. Realizamos degustações para que elas possam conhecer e provar diferentes alimentos nutritivos de forma divertida e educativa. Essas atividades fazem parte do nosso compromisso em promover hábitos alimentares saudáveis desde cedo, proporcionando experiências positivas com alimentos variados e nutritivos.
                            </text>
                        </div>
                        <div className={styles.imgProfessor}>
                            <div>
                                <img src={alimentacaoSaudavelimage} />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <Projetos
                        estrutura="Nosso Projeto"
                        propWidth="949px"
                        propPadding="unset"
                        propWidth1="256px"
                        propPaddingRight="unset"
                        frameDivMinWidth="949px"
                        frameDivDebugCommit="unset"
                        projetos={projetos}
                    />
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default ProjetoAlimentacaoSaldavel