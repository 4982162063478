import React from 'react'
import styles from './SubPagesEsportes.module.css'
import Header from '../../components/Header/Header'
import img1 from '../../ASSETS/Esportes/ballet/3acf4a29-4560-498f-8cf0-0358ce65101b.jpg'
import img2 from '../../ASSETS/Esportes/ballet/ea5762fa-55ee-4e6e-8d42-fb38adea6fe8.jpg'
import img3 from '../../ASSETS/Esportes/ballet/bed9242f-9b1e-499e-a98b-c443e054549c.jpg'
import img4 from '../../ASSETS/Esportes/ballet/bed9242f-9b1e-499e-a98b-c443e054549c.jpg'
import img5 from '../../ASSETS/Esportes/ballet/3acf4a29-4560-498f-8cf0-0358ce65101b.jpg'


import professorimg from '../../ASSETS/Esportes/ballet/791285ed-a1e7-423a-bebb-58cd91c86528.jpg'
import Footer from '../../components/Footer/Footer'
import Projetos from '../../components/ProjetosGeniais/Projetos'
const BalePage = () => {
    const projetos = [
        {
            id: 1,
            url: img1
        },
        {
            id: 2,
            url: img2
        },
        {
            id: 3,
            url: img3
        },
        {
            id: 4,
            url: img4
        },
        {
            id: 5,
            url: img5
        },
    ];
    return (
        <div className={styles.macbookAir1}>
            <Header />
            <section className={styles.EsportesSection}>
                <div className={styles.EsportesFull}>
                    <h1>Balé</h1>
                    <div className={styles.ConteudoFull}>
                        <div>
                            <text>
                                O ballet infantil desempenha um papel crucial no desenvolvimento das crianças, tanto física quanto mentalmente. Além de promover a disciplina e a coordenação motora, o ballet ajuda a melhorar a postura, a flexibilidade e a força muscular. Além disso, proporciona um ambiente onde as crianças aprendem a trabalhar em equipe, a seguir instruções e a expressar suas emoções de forma artística. Esses benefícios não apenas contribuem para o crescimento saudável da criança, mas também ajudam a desenvolver habilidades que são úteis em várias áreas da vida.
                            </text>
                        </div>
                        <div className={styles.imgProfessor}>
                            <div>
                                <img src={professorimg} />
                            </div>
                            <p>Professora Tatiane Lima Alexandre Professora Ballet Educadora Física.</p>
                        </div>
                    </div>
                    <hr />
                    <Projetos
                        estrutura="Nossa Estrutura"
                        propWidth="949px"
                        propPadding="unset"
                        propWidth1="256px"
                        propPaddingRight="unset"
                        frameDivMinWidth="949px"
                        frameDivDebugCommit="unset"
                        projetos={projetos}
                    />
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default BalePage