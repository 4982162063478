import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HeaderTop from "../components/Header/HeaderTop";
import HeaderBottom from "../components/Header/HeaderBottom";
import DivswiperContainer from "../components/DivswiperContainer";
import DataAggregator from "../components/DataAggregator";
import SocialMedia from "../components/SocialMedia";
import GroupComponent from "../components/GroupComponent";
import ProjetosGeniais from "../components/ProjetosGeniais/ProjetosGeniais";
import Footer from "../components/Footer/Footer";
import styles from "./Home.module.css";
import Header from "../components/Header/Header";
import FloatButton from "../components/FloatButtom/FloatButtom";

const Home = () => {
  const navigate = useNavigate();

  const onListItemClick = useCallback(() => {
    navigate("/macbook-air-4");
  }, [navigate]);

  const onConstantsIconClick = useCallback(() => {
    // Please sync "MacBook Air - 7" to the project
  }, []);

  const onListItem1Click = useCallback(() => {
    // Please sync "MacBook Air - 7" to the project
  }, []);

  const endereco = encodeURIComponent('Rua Viriato Ribeiro, 724, Bela Vista, Fortaleza, Ceará');

  // URL do Google Maps com o endereço
  const googleMapsUrl = `https://www.google.com/maps?q=${endereco}`;

  return (
    <div className={styles.macbookAir1}>
      <Header />
      <DivswiperContainer />
      <DataAggregator />
      <FloatButton />
      {/* Acompanhe nosssas redes sociais */}
      <section className={styles.divfaixaWrapper}>
        <div className={styles.divfaixa}>
          <div className={styles.divfaixaContainer}>
            <img className={styles.vectorIcon} alt="" src="/vector-3.svg" />
            <h1 className={styles.heading21}>Acompanhe nossas redes sociais</h1>
          </div>
        </div>
      </section>
      <SocialMedia />
      <div className={styles.image13} />
      <GroupComponent />
      <img
        className={styles.macbookAir1Child}
        loading="lazy"
        alt=""
        src="/line-1.svg"
      />
      <div className={styles.image12} />
      <ProjetosGeniais />
      <div className={styles.image11} />
      {/* Mapa */}
      <section className={styles.lineParent}>
        <div className={styles.frameChild} />
        <div className={styles.image20Wrapper}>
          <iframe
            title="Localização da Instituição"
            width="100%"
            height="450"
            loading="lazy"
            allowFullScreen
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3981.2714904050085!2d-38.56040790000001!3d-3.750949!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7c7495ce2ec0001%3A0xe91e32e2e761214b!2sR.%20Viriato%20Ribeiro%2C%20724%20-%20Bela%20Vista%2C%20Fortaleza%20-%20CE%2C%2060442-642!5e0!3m2!1spt-PT!2sbr!4v1711397729987!5m2!1spt-PT!2sbr"
            style={{ borderRadius: "10px" }}
          />
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Home;
